#cookieOverlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background: var(--overlay-colour);
}

#cookieBox {
    background: var(--non-interactive-background-colour);
    z-index: 10000;
    position: fixed;
    top: 0;
    left: auto;
    width: 100%;
    padding: 40px;
    max-height: 100%;
    overflow-y: auto;
    flex-direction: column;
    max-width: 600px;
    display: none;
    
    &.open {
        display: flex;
    }

    &.show-cookie-notice {
        max-width: 95%;
  
        @media screen and (max-width: 625px) {
          overflow-y: auto;
          max-width: 100%;
        }
  
        #cookieContent {
          width: 40%;
          display: inline-block;
          vertical-align: top;
          box-sizing: border-box;
  
          @media screen and (max-width: 625px) {
            width: 100%;
          }
        }
  
        #cookieNoticeTarget {
          box-sizing: border-box;
          width: 58%;
          padding: 5px 30px;
          display: inline-block;
          height: 95vh;
          overflow-y: scroll;
  
          @media screen and (max-width: 625px) {
            width: 100%;
            padding: 5px 30px;
            height: auto;
            overflow: visible;
          }
        }
      }

    #cookieManage {
        display: none;

        .cookieManageInnerContainer {
            display: flex;
            flex-direction: column;

            .custom-control {
                align-self: flex-end;
            }

            .cookieDivider {
                border-top: 1px solid var(--main-border-colour);
                margin: 20px 0;
            }
        }
    }

    .cookieContentContainer {
        .cookieButtons {
            display: flex;
            flex-direction: column;
    
            #cookieClose {
                align-self: end;
                margin: 10px 0;
            }
        }
    
    }

    .closeCookieNotice {
        align-self: flex-end;
    }
}
.container-404 {
    min-height: 30vh;
    padding-top: 50px;
}