.HomePageUS {
    margin-bottom: 10px;
    .box-header svg {
        max-height: 80px;
        max-width: 80px;
    }
    .home-banner-container-V2US {
        height: 810px;
    }
    #move_down {
        a {
            margin-left: 20px;
            color: var(--main-action-highlight);
            &:hover {
                color: var(--darker-action-hover-state);
            }
            i {
                font-size: xx-large;
            }
        }
    }
    .checkup-box {
        border: 1px solid var(--secondary-main-colour);
        border-radius: 6px;
        padding: 20px;
        margin: 10px 0 10px 0;
        height: 300px;
        @media screen and (max-width: 1200px) {
            height: 356px;
        }
        @media screen and (max-width: 991px) {
            height: fit-content;
            
        }
        
        .box-header {
            svg {
                max-height: 80px;
                max-width: 80px;
            }
            .box-title {
                text-align: center;
                h3 {
                    margin-top: .5rem;
                }
            }
        }
        .box-content {
            margin: 0 20px;
            text-align: center;
            p {
                font-size: small;
                color: #4b4b4b;
            }
        }
        .box-action {
            .btn {
                color: var(--bs-btn-active-color);
                background-color: var(--main-action-highlight);

                &:hover {
                    background-color: var(--darker-action-hover-state);
                }
            }
        }
    }
    .home-MakeAClaim-full-width, .home-DocumentReview-full-width, .home-AttorneyConsultation-full-width, .home-AALQ-full-width, .home-CreateALegalDocument-full-width {
        .checkup-box {
            height: auto;
            .image {
                padding-left: 20px;
                svg {
                    max-width: 130px;
                    max-height: 130px;
                }
            }
            @media screen and (max-width: 992px) {
                .image {
                    padding-left: 0;
                    display: flex;
                    justify-content: space-around;
                    svg {
                        max-width: 80px;
                        max-height: 80px;
                    }
                }
            }
        }
    }
    .home-lastedited {
        border: 1px solid var(--secondary-main-colour);
        border-radius: 6px;
        padding: 10px;
        margin: 30px 0 10px 0;
        p.lastDocumentReminder {
            margin-bottom: 0;
        }
        .post_login_homepage {
            a {
                color: var(--bs-link-color-rgb);
                text-decoration: none;
                text-decoration-color: var(--bs-link-color-rgb);
                &:hover {
                    color: var(--secondary-main-colour);
                    text-decoration: underline;
                    text-decoration-color: var(--secondary-main-colour)!important;
                }
            }
        }
    }
    // .content-block .content-block-background-image svg {
    //     max-height: 300px;
    //     max-width: 300px;
    // }
    .content-block .content-block-dark {
        border: 1px solid var(--secondary-main-colour);
        border-radius: 6px;
    }
}