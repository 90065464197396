#customerMessageForm2023 {
    padding-top: 1rem;
    .e_accountDetails {
        #customerfirstmiddleandlastname {
            display: none;
        }
        li {
            list-style-type: none;
        }
        .e_chunk1 { 
            padding-left: 0;
            .e_item {
                padding-bottom: 1rem;
            }
        }
        .e_chunk2 {
            padding-left: 0;
            .e_item {
                padding-bottom: 1rem;
            }
        }
    }
}
