$landscape-break-lg: 1200px;
$landscape-break-med: 990px;
$landscape-break-sm: 550px;

.ele-landscape  {

    #landscapeWindow {
        background: url(/common/images/landscape/bkg.jpg) no-repeat;

        max-width: 794px;
        max-height: 400px;
        height: 45vw;
        margin: 0 auto;
        background-size: cover;

        border: 3px solid #6b410d;
        border-bottom-color: #44250a;
        border-right-color: #44250a;

        position: relative;

        #landscapeWindowItems {
            width: 100%;
            height: 100%;
            position: absolute;

            img {
                position: absolute;
            }
        }

        svg {
            position: absolute;
        }
    }
    .e_selector{
        box-sizing: content-box;
        max-width: 1000px;
        margin: 30px auto 40px;
        border: 5px solid var(--secondary-main-colour);
    }

    .e_selector_questions {
        background: #fff;
    }

    .e_selector_tabs_nav { display:none; }

    .e_selector_tabs {
        border-bottom: none;
        background: #ffffff;
        margin-bottom: 0;
        align-items: center;
        justify-content: center;
        .e_selector_tab {
            margin-right: 0;
            padding: 0;
            a, span {
                box-sizing: border-box;
                width: 100px;
                height: 100px;
                padding: 5px;
                display: inline-block;
                font-size: 2.3rem;
                color: var(--landscapr-image-highlight);
                i { margin: 20px; font-size: 3rem;}

            }
            a {  background: var(--main-action-highlight); cursor: pointer; }
            a:hover {  background: var(--darker-action-hover-state); }
            span.active { background: var(--secondary-main-colour); }
            span { background: rgba(6, 85, 112, 0.24); }
        }
    }

    .e_selector_question_content {
        background: var(--secondary-main-colour);
        color: #fff;
        padding: 10px 20px;
        font-size: 1.1em;
        p { margin: 0; }
    }

    .e_selector_question .e_selector_answers {
        padding: 30px 30px 20px 30px;
        font-size: 1.1em;
        min-height: 205px;

        label {
            background-color: #fff;
            border-radius: 4px;
            cursor: pointer;
            padding: 4px 8px 4px 34px;
            margin: 0;
            &:hover {
                background-color: rgba(6,85,112,.24);
            }
        }
        input[type=radio] {
            position: absolute;
            top: 11px;
            left: 9px;
            margin: 0;
        }

        .e_selector_input_container {
            float: none;
            padding: 0 0 10px 0;
            position: relative;
            width: 60%;

            @media (max-width: $landscape-break-sm) {
                width: auto;
            }

            &.disabled {
                label {
                    background-color: #fff;
                    cursor: default;
                }
            }

            &.active {
                label {
                    //background-color: #41D3BD;
                }
            }
        }
    }

    .e_selector_buttons {
        float:right;
        padding: 25px 30px;
        margin-top: -86px;

        @media (max-width: $landscape-break-sm) {
            margin-top: -45px;
        }
    }

    .e_selector_result {
        border: none;
        background: var(--box-dark-background);
        padding: 15px;
        .e_selector_print { display: none; }

        .e_selector_result_content {
            background: #fff;
            max-width: 665px;
            margin: 0 0 0 150px;

            @media (max-width: $landscape-break-med) {
                width: auto;
                position: relative;
                left: 0px;
                right: 20px;
                margin: 0;
            }
        }

        #landscape_tabs {
            width: 500px;
            margin: 0 auto;
            a {
                width: 50%;
                display: block;
                float:left;
                padding: 6px 10px;
                background: var(--secondary-main-colour);
                color: #fff;
                box-sizing: border-box;
                &.active {
                    background: #fff;
                    color: var(--secondary-main-colour);
                }
            }
        }

        .e_selector_healthcheck_intro {
            width: 500px;
            margin: 0 auto;
            overflow: hidden;
            div { overflow: hidden; }
            img {float: left; padding: 10px;}
            p { float: left; padding: 20px 10px 10px 0; width: 400px; }
        }

        .e_selector_healthcheck_result {
            border-top: 1px solid rgba(15,126,175,.2);
            padding-top: 15px;
            position: relative;
            min-height: 105px;

            @media (max-width: $landscape-break-med) {
                min-height: 125px;
            }

            .icon {
                display: block;
                height: 80px;
                width: 80px;
                font-size: 26px;
                margin: 0 15px;
                position: absolute;
                background: var(--main-action-highlight);
                padding: 5px;
                color: #fff;

                @media (max-width: $landscape-break-sm) {
                    height: 60px;
                    width: 60px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: var(--darker-action-hover-state);
                }
            }
            a {
                display: block;
                padding: 0 15px;
                margin: 0 15px 10px 105px;
                font-size: 1.0rem;
                color: var(--main-action-highlight);

                @media (max-width: $landscape-break-sm) {
                    margin-left: 85px;
                }

                &:before {
                    font-family: "Font Awesome 6 Pro";
                    content: "\f573";
                    font-weight: 700;
                    font-size: 1.3em;
                    padding: 5px;
                    border-radius: 20px;
                    width: 30px;
                    display: inline-block;
                    height: 30px;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 10px;
                    box-sizing: border-box;
                    //background: #3ba4c0;
                }

                &:hover {
                    text-decoration: underline;
                    color: var(--darker-action-hover-state);
                }
            }

            .landscape_product a {
                font-weight: bold;
            }
            .landscape_lawguide a:before {
                content: "\f24e";
            }
            p { margin: 0; }
            .i_tooltip {
                @media (max-width: $landscape-break-med) {
                    display: block;
                    border-bottom: 1px dotted rgba(15,126,175,.2);
                    padding: 0 10px 0 10px;
                    margin: -12px 0 10px 0;
                    font-size: 1rem;
                }
                @media (min-width: $landscape-break-med) {
                    position: absolute;
                    background: #fff;
                    padding: 8px 8px;
                    width: 100px;
                    text-align: center;
                    font-size: .9rem;
                    left: -133px;
                    top: 14px;
                    line-height: 1.2em;
                    min-height: 30px;
                }
            }
            .i_tooltip:after {
                @media (min-width: $landscape-break-med) {
                    left: 100%;
                    //top: 50%;
                    top: 23px;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(255, 255, 255, 0);
                    border-left-color: #fff;
                    border-width: 8px;
                    margin-top: -8px;
                }
            }
        }

        .landscape_product, .landscape_lawguide {
            background-color: #fff;
            padding: 0 0 1px 0;
        }
    }
    .e_selector_reset_container {
        background: var(--secondary-main-colour);
        padding: 2px 6px;
        a { color: #fff; text-decoration: underline;}
    }
}

.ele-landscape .e_selector_tabs .e_selector_tab a.tooltip {
    opacity: 1;
    position: relative;
}

//get the tabs working for mobile resolutions
@media (min-width: $landscape-break-med) and (max-width: $landscape-break-lg) {
    .ele-landscape .e_selector { width: 800px;}
    .ele-landscape .e_selector_tabs .e_selector_tab a,
    .ele-landscape .e_selector_tabs .e_selector_tab span {
        width: 80px;
        height: 80px;
    }

    .ele-landscape .e_selector_tabs .e_selector_tab a i,
    .ele-landscape .e_selector_tabs .e_selector_tab span i { font-size: 2rem;}
}
@media (min-width: $landscape-break-sm) and (max-width: $landscape-break-med) {
    .ele-landscape .e_selector { width: 500px; }
}
@media (max-width: $landscape-break-sm) {

    .e_selector {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .ele-landscape .e_selector_tabs {
        width: 40%;
    }
    .ele-landscape .e_selector_tabs .e_selector_tab {
        width: 100%;
        display: none;
        &.active {
            display: block;
        }
    }
    .ele-landscape .e_selector_tabs_nav {
        color: rgba(15,126,175,.7);
        text-align: center;
        display: block;
        width: 30%;
        font-size: 2.6rem;
        i{
            padding:10px;
            cursor: pointer;
        }
        &:hover {
            color: rgba(15,126,175,.6);
        }
        &.disabled { visibility: hidden; }
    }
    .ele-landscape .e_selector_tabs .e_selector_tab a,
    .ele-landscape .e_selector_tabs .e_selector_tab span {
        width: 100%; height: auto;
    }
    .ele-landscape .e_selector_questions,
    .ele-landscape .e_selector_reset_container,
    .ele-landscape  .e_selector_results
    { clear:both;  flex-basis: 100%;}
}

.e_selector {
  
    .e_selector_question {
        h3 { display: none; }
  
        .e_selector_number { display: none; }
  
        .e_selector_question_content { padding-top: 10px; }
    }
  
    .e_selector_answers {
        overflow: hidden;
    }
  
    .e_selector_tabs {
        border-bottom: 2px solid #ccc;
        margin-bottom: 10px; padding-bottom: 5px;
  
        .e_selector_tab {
            display: inline-block;
            margin: 7px;
  
            @media (max-width: 550px) {
                display: none;
            }
  
            a.tooltip {
                opacity: 1;
                position: relative;
            }
        }
    }
  
    .e_selector_question .e_selector_input_container {
        float: none;
        overflow: hidden;
        display: flex;
        input {
            //margin: 5px;
            display: block;
        }
    }
  
    .e_selector_warning {
        color: #dc0000;
        border: 2px solid #dc0000;
        padding: 1em 1em 0;
        margin-top: 15px;
    }
  
    .e_selector_reset_container {
        padding: 20px 0 10px 0;
        clear: both;
        background: none;
        border-top: 4px solid var(--secondary-main-colour);
    }

    #e_selector_reset {
        background: var(--main-action-highlight);
        border: 0px solid var(--main-action-highlight);
        color: #fff;
        padding: .4rem 2rem;
        border-radius: 40px;
        margin: 2rem;
        text-decoration: none;
        &:hover {
          background: var(--darker-action-hover-state);
          text-decoration: underline;
        }
      }
  
    .e_selector_result  {
        margin-top: 10px;
        //border: 2px solid var(--darker-action-hover-state);;
        border-bottom: 2px solid #44263a;
        padding: 25px 33px;
  
        h2 { display: none }
  
        h3 {
            background: url(/common/ele/images/document_b.png) no-repeat 0px 0px;
            padding-left: 50px;
            line-height: 40px;
            float:left;
        }
  
        .e_selector_result_oneprice {
            font-size: 1.6em;
            line-height: 40px;
            float: right;
            color: #444;
        }
  
        .e_selector_result_content {
            clear: both;
        }
  
        .e_selector_result_try  { display: none; }
    
        .e_selector_result_services {
            strong, span { display: none; }
            a {
            float: right;
            }
        }
    }
  
    .e_body_selectorLandlord {
        .e_selector_result_content {
            ul {
                padding-left: 40px;
                margin-bottom: 1em;
                li { list-style: disc; }
            }
        }
    }
}
  
.ele-landscape {
    .e_selector_tabs {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        display: flex;
        .e_selector_tab {
            margin: 0;
        }
    }
}