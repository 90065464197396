.AALQ_Container {
    padding: 15px 2rem 2rem 2rem;
}

.aalq_workflow_component_container {
    display: flex;
    text-align: center;
    max-width: 1400px;
    margin: 30px auto 0 auto;
    justify-content: center;

    .component_title {
      margin-bottom: 30px;
    }

    .component_intro {
        margin-bottom: 25px;
    }

    component_btn {
        margin-top: 20px
    }    

    img {
        width: 200px
    }

    @media (max-width: 1200px){
        

    }

    @media (max-width: 900px){
        
    }
    

    .aalq_state_selection {
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
    }

    .aalq_transition_content {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    
    .refinedQuestionOutput {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        text-align: center;
        color: var(--AALQHighlight);
        padding: 20px;
        font-size: 1.3rem;
        align-items: flex-start;
        line-height: 1.7rem;  
        
        div {
            margin-left: 20px;
            width: 100%;
        }
        
        i.fa-message {
            font-size: 45px;
            margin-top: 8px;
        }

        .skeletonWrapper {
            width: 100%;            
        }
    }

    .no_additional_details_container {
        justify-content: space-between;
    }
}

.aalq_body {
    .pageContainer {
        display: block;
        height: auto;
    }

    main {
        min-height: calc(100vh - 100px);

        .aalq {
            position: relative;
        }

        .aalq_app_container { 

            h3 {
                font-size: 1.25rem;
            }


            .workflow_steps {
                text-align: center;
                margin: 30px auto 0 auto;
                display: flex;

                @media (max-width: 600px){
                    display: flex;
                    margin: 0 auto 0 auto;
                    .MuiStepper-root.MuiStepper-vertical.MuiStepper-alternativeLabel.workflow_container {
                        align-items: center;
                    }
                }

                justify-content: center;

                .MuiStepLabel-label.Mui-active {
                    font-size: 1.2rem;
                }

                .css-1pcw1to {
                    text-align: center;
                }
                .MuiStepConnector-root {
                    @media (max-width: 600px){
                        display: none;
                    }
                }
                
              }

            .workflow_container {
                min-width: 80%;
            }
    
            .aalq_question_refined_from_server {
                padding: 20px;
                text-align: center;
                background: #eef8fd;

                p {
                    margin-bottom: 0;
                }
            }        

            .aalq_option_intro {
                margin: 30px 0;
            }

            .aalq_question_container {
                @media (max-width: 600px){ 
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column-reverse;
                }
                .no_additional_details_container {
                    width: 25%;                    
                    padding-right: 2.5%;
                    margin-right: 2.5%;
                    border-right: 1px solid #ccc;
                    display: flex;
                    flex-direction: column;
                    @media (max-width: 600px){
                        width: 100%;
                        margin-top: 1rem;
                        border-right: unset;
                        border-top: 1px solid #ccc;
                    }
                }

                .yes_additional_details_container {
                    width: 75%;
                    border: 0 none;
                    display: flex;
                    flex-direction: column;
                    @media (max-width: 600px){
                        width: 100%;
                    }
                }

                h3 {
                    font-size: 1.3rem;
                }

                button {
                    margin-top: 20px;
                }
            }

            .initial_details_container {
                p {
                    max-width: 1000px;
                }
            }

            .TOE_container {
                max-height: 500px;
                overflow-y: scroll;
                border: 1px solid #ccc;
                padding: 20px;
                text-align: left;

                h1 {
                    font-size: 1.3rem;
                    text-align: center;
                }

                h2{
                    font-size: 1.2rem;
                    text-align: left;
                }

                h3 {
                    font-size: 1.1rem;
                }

                h4 {
                    font-size: 1rem; 
                }
            }

            .aalq_buttonGroup {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
            }

            .aalq_introduction_container {
                text-align: center;
                margin-top: 25px;
            
                .aalq_introduction_container_inner {
                    display: flex;
                    justify-content: center;
                    margin: 25px 0;
            
                    .aalq_logo {
                        width: 25%;
                    }
            
                    .aalq_introduction {
                        text-align: justify;
                        margin-left: 30px;
                        max-width: 945px;
                        @media (max-width: 600px){
                            margin-left: 0;
                        }
            
                        h2 {
                            margin-bottom: 25px;
                        }
                    }

                    img {
                        @media (max-width: 600px){
                            display: none;
                        }
                    }
                }
            }

            .aalq_finish_container {
                .aalq_question_refined_from_server {
                    padding: 40px;
                    p {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .restart-btn {
        font-size: .8rem;
        background: transparent;
        border: 1px solid var(--dark-text-high-emphasis);
        color: var(--dark-text-high-emphasis);
        padding: 3px 15px;
        margin-top: 5px;
    }

    .MuiStepper-root {
        .step {
          &.current {
            // Target elements that are current but not completed
            &:not(.Mui-completed) {
              .MuiStepLabel-horizontal {
                position: relative;
                top: -8px;
              }
      
              .MuiStepLabel-label {
                margin-top: 7px;
              }
            }
          }
        }
      }
      
}

.aalq_terms_acceptance_detail {
    margin-top: 20px;
    font-size: 0.9rem;

    ul {
        text-align: left;
    }
}

.firm_highlight {
    color: darkolivegreen;
    font-size: 1.3rem;
}

.aalq_firm_intro {
    text-align: center;
    font-size: 1.2rem;
}

.slightly_larger {
    font-size: 1.25rem;
  }

.skeleton-shimmer {
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

