
.my-documents-flex--wrapper {
    @include media-breakpoint-down(lg){
        margin-top: -50px;
        //padding: 0 1rem;
    }
    
    a {
        text-decoration: none;
    }

    .e_mysToolbar, .e_documents_how_this_works {
        display: none;
    }
    
    .e_start_new_document_brp {
        float: left;
        display: block;
        margin-bottom: 1rem;
        margin-right: 1rem;
        width: 307px; 

        a {
            width: 100%;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .e_upload_new_document {
        float: left;
        clear: left;
        display: block;
        margin-bottom: 1rem;
        margin-right: 1rem;
        @include media-breakpoint-down(lg) {
            display: none;
        }

        a {
            border: 0.0625rem solid var(--icon-border);
            background: var(--icon-color);
            color: var(--bs-link-color);


            &:hover {
                border-radius: 40px;
                border: 1px solid var(--icon-background);
            }

        }
    }

    .e_documents_filtering,.e_document_drag.e_dragger  {
        @include media-breakpoint-down(lg){
            display: none;
        }
    }


    .e_documents_filters.box {
        margin-right: 20px;

        a:hover {
            text-decoration: underline;
        }

        .e_documents_create_folder {

            a {
                border: 0.0625rem solid var(--icon-border);
                background: var(--icon-color);
                color: var(--bs-link-color);
    
    
                &:hover {
                    border-radius: 40px;
                    border: 1px solid var(--icon-background);
                }

            }
        }
    }

    .e_documents_list {
        width: 74%;
        display: inline-block;
        position: relative;
        overflow: visible;
        margin-left: 0;
        @include media-breakpoint-down(lg){
            width: 100%;
            margin-left: 0;
            margin-top: 1.5rem;
      }

      .e_documents_list_title {
        margin-left: 0.5rem;
      }

      .e_documents_pagination {
        margin-left: 10px;
        .e_documents_pagination_controls {
            margin-right: 10px;
        }
      }

      .e_documents_pagination #documents_page {
        max-width: 1.8rem;
      }
        
        .e_documents_search_container {
            .e_documents_search {
                border: none;
                background: none;

                @media (max-width: 600px){
                    padding: 0;
                }
                label {
                    font-weight: 600 !important;
                    @media (max-width: 600px){
                        font-size: 0.75rem;
                    }
                }
                .e_field {
                    @media (max-width: 600px){
                        flex: 1;            
                    }
                    
                    button {
                        padding: 0.2rem 1rem;
                        border: 0.0625rem solid var(--icon-border);
                        background: var(--bs-link-color);
                        color: var(--icon-color);


                        &:hover {
                            border-radius: 40px;
                            border: 1px solid var(--icon-background);
                        }
                    }
                }
                
                 input {
                    padding-right: 0 !important;
                }
                
                .inner-flex {
                    #documents_sort_ascending {
                        @media (max-width: 800px){
                            display: none;
                        }
                    }
                }

                .search-flex {
                    justify-content: flex-start;
                    
                    @media (max-width: 800px){
                        flex-direction: row !important;
                        
                    }
                    

                    @media (max-width: 600px){
                        flex-wrap: wrap;   
                        .e_field:last-child {
                            flex-basis: 100%;
                            align-items: end;
                          }
                    }
                    
                }
            }
        }

        .e_list {
            .e_documents {
                .e_document {
                    &:before {
                        content: " ";
                        background: #dcdcdc;
                        width: 25px;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 700;
                        padding-left: 6px;
                        padding-top: 12%;

                        @include media-breakpoint-down(lg){
                            background: none;
                        }
                    }
                    padding: 10px 10px 10px 40px;
                    border-radius: 4px;
                    background: var(--non-interactive-background-colour);
                    margin-left: 13px;

                    @include media-breakpoint-down(lg){
                        margin-left: 0;
                        padding: 20px;
                    }

                    .e_document_flags.e_document_customer_flags {
                        position: relative;
                        right: 0;

                        .e_document_percentage {
                            display: none;
                            border: 2px solid var(--secondary-main-colour) !important;
                            @media (max-width: 600px) {
                                display: block;
                            }
                        }

                    }

                    .e_document_drag.e_dragger {
                            position: absolute;
                            left: 0;
                            background: none;
                            top:8px;
                            height: 100%;
                            cursor: grab;
                            width: 25px;

                        &:before {
                              position: absolute;
                              left: 8px;
                              top: 5px;
                              font-family: "Font Awesome 6 Pro";
                              font-weight: 900;
                              font-style: normal;
                              font-size: inherit;
                              text-rendering: auto;
                              -webkit-font-smoothing: antialiased;
                              -moz-osx-font-smoothing: grayscale;
                              transform: translate(0, 0);                              
                              color: #000;
                              content: "\f58e";
                        }

                        &:hover {
                            background: #94A8F9;
                
                            &:before {
                              content: "\f0b2";
                              color: #000;
                              left: 5px;
                            }
                
                          }
                    }

                    .e_document_name {
                        text-align: left;
                        padding: 0;
                        margin-bottom: 0.3rem;
                        word-wrap: break-word;

                        a {
                        color: var(--dark-text-high-emphasis);
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: -0.48px;
                            &:hover {
                                text-decoration: underline;
                            }
                        }

                    }
                    

                    .e_action_buttons {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 8px;
                            margin-bottom: 1rem;

                            @include media-breakpoint-down(lg){
                                    display: flex;
                                    flex-direction: column;
                                    gap: 1rem;
                                    align-items: flex-start;
                              }

                              @media (max-width: 600px){
                                margin: 0.8rem 0;
                              }

                              .e_btn {
                                padding: unset;
                                
                                a {
                                    &:hover {
                                        background: var(--main-action-hover-state);
                                        color: var(--light-text-standard);
                                        text-decoration: none; 
                                    };
                                }

                                .e_disabled {
                                    border-radius: 40px;
                                    height: 40px;
                                    padding: .4rem 2rem;
                                    background: var(--main-action-highlight);
                                    opacity: 0.3;
                                    color: white;
                                    text-decoration: none;
                                    &:hover {
                                        cursor: not-allowed;
                                    }
                                }
                             }
                            
                            a {                               
                            border-radius: 40px;
                            height: 40px;
                            padding: .4rem 2rem;
                            background: var(--main-action-highlight);
                            color: white;
                            text-decoration: none;

                        }

                        a {
                            color: var(--light-text-standard)!important;
                        }

                        .e_btn.e_btn_edit.e_disabled {
                            background: none;
                        }
                        
                    }

                    .e_btn_reviewMessage {
                        text-align: left;
                        margin-bottom: 10px;

                        .e_btn_outOfScopeMessageContent {
                            color: var(--alert-error);
                        }
                    }

                    .e_document_menu {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        border-top: none;
                        padding-left: 0;
                        margin-top: 0.3rem;


                        ul  {
                            text-align: left;
                            padding-left: 0;
                            padding-right: 0;
                            display: flex;
                            flex-wrap: wrap;
                            list-style: none;

                            li {
                            border-right: none;
                            margin-right: 0;
                            margin-bottom: 0.1875rem;
                            margin: 0.3rem 0.3rem 0.3rem 0;
                            padding: 0;

                            &.e_document_more {
                                font-weight: bold;
                                //margin-left: 0.25rem;
                                order: 1;
                                
                            }

                            .e_btn {
                                color: var(--bs-link-color);
                                &.e_disabled {
                                    background: none;
                                    color: #000;
                                    padding: unset;
                                }
                            }

                               a {
                                //display: flex;
                                padding: 0 0.5rem;
                                align-items: center;
                                border-radius: 1rem;
                                border: 0.0625rem solid var(--icon-border);
                                color: var(--icon-background);

                                &:hover {
                                    border-radius: 40px;
                                    border: 1px solid var(--icon-background);
                                }
                                background: var(--icon-color);
                               } 
                            }
                        }

                        .e_btn_disabled {
                            border-radius: 40px;
                            border: 1px solid var( --icon-border);
                            opacity: 0.5;
                            padding: 0 0.5rem;
                        }

                        .e_document_menu_sub {
                            display: none;
                        }
                    }

                    .e_inlinePercentage_bar {

                        display: flex;
                        gap: 4px;
                        align-items: center;
                        padding-bottom: 15px;

                        @media (max-width: 600px){
                                display: none;
                            }
                        .bar_container {
                            width: 400px;
                            height: 16px;
                            border-radius: 40px;
                            background: var(--ui-secondary, #CFCFE6);

                                .bar_percentage {
                                    background:var(--secondary-main-colour);
                                    height: 16px;
                                    border-radius: 40px
                                }
                                @include media-breakpoint-down(xl){
                                    width: 400px;
                                }
                            }

                            .e_document_percentage_approved {
                                i {
                                    color: green;
                                }
                            }

                            .e_document_percentage {
                                border: none !important;
                            }
                        }

                    }

                    .e_document.e_document_dragged {
                        z-index: 2000;
                        border: 1px solid #94a8f9;
                        cursor: grabbing;
                    }

                    .e_document_details {

                        background: none;
                        ul {
                            padding-left: 0;
                            text-align: left;
                            display: flex;
                            justify-content: space-between;
                            gap: 1rem;
                            @include media-breakpoint-down(md){
                                flex-direction: column;
                                gap: 0;
                            }

                          
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: baseline;
                                justify-content: space-between;

                                  @media (max-width: 600px){
                                    flex-direction: row;
                                    gap: 0.5rem;
                                    font-size: 0.8rem;
                                    justify-content: flex-start;
                                    }


                                span.e_chunk1 {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 4px;
                                    i {
                                        color: var(--secondary-main-colour);
                                    }
                                }

                                span.e_chunk2 {
                                    font-weight: bold;
                                    color: var(--dark-text-high-emphasis);
                                    text-align: left;
                                }
                                &.e_inlinePercentage {
                                    display: none;
                                }

                                
                            }
                        }
                    }
                }
            }
        }
    }


    #ele-user-documents-view {
        text-align: left;
        
        .loading {
            display: none;
            text-align: center;
            width: 100%;
            margin-top: 20px;
        }
     
        .uploadForm {
            padding: 20px;
            border: 1px solid var(--main-border-colour);
            background: var(--non-interactive-background-colour);
            margin-bottom: 20px;
        }

        .formGroup {
            margin-bottom: 20px;
        }

        .hidden {
            display: none;
        }

        #uploadedFileName {
            width: 100%;
        }

        #uploadFormSubmit {
            color: var( --light-text-standard);
            &:disabled {
                opacity: .3;
                background-color: var(--main-action-highlight);
                cursor: not-allowed;
            }
        }

        .learn-more {
            a {
                text-decoration: none;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }   

}


.myServices {
    //margin: 1.5rem 0;
    h1 {
        margin-top: 1rem;
    }
    @media (max-width: 600px){
        margin-top: -30px;
    }
    .e_mysToolbar {
        .e_msNav {
            display: none;
        }
    }
    .e_summary_components {
        text-align: left;
        a {
            text-decoration: none;
        }
   
    .e_summary_component {
        padding: 1.25rem;
        box-sizing: border-box;
        background-color: var(--non-interactive-background-colour);

        h2 {
            margin: 0;  
        }
        p {
            margin-bottom: 0.5rem;
        }
        h3 {
            margin: 1.5625rem 0 0.5rem 0;
            text-align: left;
        }

        .e_summary_bottom.templates {
            display: none;
        }

    }

    .e_summary_component_content {
        margin-top: 1rem;
        .e_summary_account_link_security {
            display: none;
        }
    }

    .e_summary_templates {
        display: none;
    }


    .e_summary_mydocs {
            .e_summary_mydocs_freeTrials {
                display: none;
            }
        }

        #activityContent {
            max-height: 400px;

            .table {
                margin-bottom: 1.25rem;
                thead {
                    display: revert !important;
                }
                th {
                  font-weight: bold;
                }
              
                td, th {
                  padding: 0.3125rem 0.625rem;
                  border: 0.0625rem solid var(--dark-text-high-emphasis);
                }

                .audit-log-icon {
                    height: 1.4375rem;
                    text-align: center;
                    display: block;
                    overflow: hidden;
                    float: left;
                    margin-right: 20px;
                    margin-bottom: 20px;
                
                    &:before {
                        display: inline-block;
                        content: url('/common/images/user_docs_history_icon.png');
                    }
                }


                
                .e_document_history_type_1000 .audit-log-icon:before {
                    margin-top: -400px;
                }
                
                //purchased
                .e_document_history_type_1001 .audit-log-icon:before {
                    //margin-top: -26px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f319";
                }
                
                //created
                .e_document_history_type_2001 .audit-log-icon:before,
                .e_document_history_type_2002 .audit-log-icon:before,
                .e_document_history_type_2003 .audit-log-icon:before,
                .e_document_history_type_2004 .audit-log-icon:before,
                .e_document_history_type_2005 .audit-log-icon:before,
                .e_document_history_type_2006 .audit-log-icon:before,
                .e_document_history_type_2007 .audit-log-icon:before,
                .e_document_history_type_2008 .audit-log-icon:before {
                    margin-top: -53px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f1e0";
                }
                
                //share
                .e_document_history_type_1020 .audit-log-icon:before {
                    margin-top: -187px;
                }
                
                //saved
                .e_document_history_type_4003 .audit-log-icon:before,
                .e_document_history_type_1002 .audit-log-icon:before {
                    margin-top: -100px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f5ac";
                }
                
                //rename & ref data
                .e_document_history_type_1009 .audit-log-icon:before,
                .e_document_history_type_2004 .audit-log-icon:before,
                .e_document_history_type_2008 .audit-log-icon:before,
                .e_document_history_type_1010 .audit-log-icon:before {
                    margin-top: 0;
                }
                
                //duplicate & copy
                .e_document_history_type_3001 .audit-log-icon:before,
                .e_document_history_type_3002 .audit-log-icon:before,
                .e_document_history_type_1039 .audit-log-icon:before {
                    margin-top: -78px;
                }
                
                //note
                .e_document_history_type_3003 .audit-log-icon:before,
                .e_document_history_type_3004 .audit-log-icon:before {
                    margin-top: -369px;
                }
                
                //download
                .e_document_history_type_1006 .audit-log-icon:before,
                .e_document_history_type_1011 .audit-log-icon:before {
                    margin-top: -337px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f019";
                }
                
                //delete
                .e_document_history_type_1007 .audit-log-icon:before {
                    //margin-top: -128px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f1f8";
                    
                }
                
                //restore
                .e_document_history_type_1008 .audit-log-icon:before {
                    //margin-top: -158px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f829";
                }
                
                //restore
                .e_document_history_type_1021 .audit-log-icon:before {
                    margin-top: -263px;
                }
                
                //review Doc Version
                .e_document_history_type_1030 .audit-log-icon:before {
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f872";
                }
                

                .e_document_history_type_1035 .audit-log-icon:before {
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f4fc";
                }
                .e_document_history_type_1031 .audit-log-icon:before, 
                .e_document_history_type_1034 .audit-log-icon:before,
                .e_document_history_type_1038 .audit-log-icon:before,
                .e_document_history_type_1040 .audit-log-icon:before {
                    margin-top: -507px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f31a";
                }
                
                //lawyer send + generic responses
                //.e_document_history_type_1034 .audit-log-icon:before { backgroun d-position: 0px -455px; } //lawyer return - orange ?
                .e_document_history_type_1032 .audit-log-icon:before,
                .e_document_history_type_1037 .audit-log-icon:before {
                    //margin-top: -429px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f316";
                }
                
                //lawyer unapproved
                .e_document_history_type_1033 .audit-log-icon:before {
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f317";
                }
                .e_document_history_type_1036 .audit-log-icon:before {
                     font-family: 'Font Awesome 6 Pro';
                    content: "\f506";
                }
                
                //lawyer reject
                .e_document_history_type_5001 .audit-log-icon:before,
                .e_document_history_type_5002 .audit-log-icon:before {
                    //margin-top: -535px;
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f0ec";

                }
                
                .e_document_history_type_6001 .audit-log-icon:before,
                .e_document_history_type_6002 .audit-log-icon:before,
                .e_document_history_type_6003 .audit-log-icon:before,
                .e_document_history_type_6004 .audit-log-icon:before {
                    margin-top: -563px;
                }

                //Upload
                .e_document_history_type_2012 .audit-log-icon:before,
                .e_document_history_type_2013 .audit-log-icon:before {
                    font-family: 'Font Awesome 6 Pro';
                    content: "\f093";
                }
                
              }
        }
    
    }

    .ele-MyServices-MySubscriptions {

        ul {
            list-style: none;
        }
        .e_mysToolbar {
           display: none;
        }

        .e_wrapCols {
            .e_nav {
                width: 24%;
                float: left;

                .e_box1 {
                    h6 {
                        font-weight: bold;
                    }

                    .content {
                        p {
                            margin: 1.5rem 0;
                        }
                    }
                }
            }

            .e_list {
                width: 75%;
                float: left;
                

                .e_subscriptions {
                    margin-left: 2rem;

                    .e_details {
                        text-align: left;

                        .e_titleSubName {
                            margin: 1.5rem 0;
                        }

                        .e_label {
                            font-weight: bold;
                        }

                        .e_templates {
                            display: none;
                        }

                        .e_changePaymentWrap {
                            margin: 1rem 0;

                            .e_control a {
                                text-decoration: none;
                            }
                        }
                    }

                    .e_schedule.e_verified {
                        text-align: left;
                        margin: 1.5rem 0;

                        li.e_paymentItem {
                            list-style: disc;

                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

}

input#filename {
    width: 100%;

    &:disabled {
        cursor: not-allowed;
    }
}

#export_loading_mask .export_buttons {
    display: flex;
    flex-direction: row-reverse;
}

body.myDocuments .e_documents_filter_selected, body.myDocuments .e_documents_folder_selected {
    background-color: var(--documents-folder-background);
    border-color: var(--documents-folder-background);
    border-right: 4px solid var(--documents-folder-border);
}

.myServices {

    .content-block {
        margin-top: 0;
    }


    .e_documents_filter {
        padding: 5px 10px;
    }

    .e_documents_folder {
       padding: 5px 10px;
       margin: 10px 0;
    }
}




/* dragging */
body.e_dragging {
    .e_start_new_document_brp,
    .e_upload_new_document,
    .e_documents_folder_delete {
      display: none;
    }
  
    .e_documents_list {
      overflow: visible;
    }
  }

  @media (max-width: 600px){
    .document_span {
        display: none;
    }

    .e_document_percentage {
        display: inline;
    }
    
    .e_documents .e_document {
        margin-left: 0 !important;
    }

}

.e_documents_list .e_list .e_documents .e_document .e_document_name .e_document_original_name {
    color: var(--documents-old-name);
    margin-bottom: 10px;
    font-size: 16px; 
}


.document-share-form-revoke-access {
    padding: 0.4rem 2rem !important;
    border-radius: 40px;
    letter-spacing: var(--button-letter-spacing);
    background: var(--main-action-highlight);
    color: var(--light-text-standard);
    border: 0 none;
    font-size: 1rem;
    &:hover {
        background: var(--darker-action-hover-state);
        color: var(--light-text-standard);
        text-decoration: none;
    }
}
.myServices .e_documents .e_document.objectid_10980 {
    &:after {
        content: '';
        background: #fff url(/common/images/Risk.png) center center no-repeat;
        background-size: 124px;
        border-radius: 4px;

        position:absolute;
        top: 12px;
        right: 12px;
        bottom: 12px;
        width: 157px;
    }
}
.e_documents {
    .e_document {
        .e_btn.e_btn_sendForLegalReview.e_disabled, .e_btn.e_btn_edit.e_disabled {
            background: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

#document-share-modal {
    #document-invite-generate-password {
        float: right!important;
        margin-top: 5px;
    }
    #document-share-modal-invite-form {
        .field {
        margin: 0;
        }
    }
    .label_checkbox input[type=checkbox] {
        margin: 10px;
        transform: scale(1.5);
    }
    label {
        font-size: 16px!important;
    }
    .document-invite-success-customer,
    .document-invite-success-email {
        padding-top: 15px!important;
        i {
        color: var(--main-action-highlight);
        }
        .document-invite-success-content {
            #document-invite-email-btn {
                color: var(--main-action-highlight);
                text-decoration: none;
                &:hover {
                text-decoration: underline;
                }
            }
        }
    }
}
.myDocuments {
    form {
        label {
            color: var(--main-form-label);
        }
    }
}
