svg {
    .colour0 {
        fill: var(--secondary-main-colour);
    }
    .colour1 {
        fill: var(--grey-scale-04);
    }
    .colour2 {
        fill: var(--grey-scale-04);
    }
    .colour3 {
        fill: var(--grey-scale-04);
    }
    .colour4 {
        fill: var(--bs-btn-active-color);
    }
    .colour6 {
        fill:none;
        stroke:#00D3FF;
        stroke-width:1.7;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-miterlimit:10;
    }
    .colour7 {
        fill: var(--non-interactive-background-colour);
    }
    
    .colour8 {
        fill: #F7F7F7;
    }
    
    width: 100%; 
    height: 100%;
}

.subsite-header-icon {
    svg {
        max-width: 4.375rem;
    }
}

.sub-page-banner-block {
    svg {
        max-width: 4.375rem;
    }
}

.howItWorksSVG {
    width: 50%;
    height: 80%;
}
