@media print {

    body {
        padding-top: 0!important;
    }

    .printing {
        div:not(.printarea) { display:none!important; }
        header, main, footer { display:none!important; }
        div.printarea * { 
            display: inline-block!important; 
            height: auto!important; 
            h1 {
                margin-top: 1rem !important;
                margin-bottom: 1.5rem !important;
                display: block!important;
            }
            h2 {
                display: block!important;
                margin-bottom: 0.5rem !important;
            }
            
            ol {
                list-style-type: decimal !important;
                margin-bottom: 1rem !important;
                padding-left: 2rem !important;

                li {
                    display: list-item !important;
                }

                &[type="a"] {
                    list-style-type: lower-alpha !important;
                    margin-left: 10px !important;
        
                    li {
                        list-style-position: inside !important;
                    }
                  }
            }

            .dpp_body {

                counter-reset: section;
            
                h2:before {
                    counter-increment: section;
                    content: counter(section) ". ";
                    margin: 0 0.5rem 0 0;
                }

                p {
                    display: block !important;
                    strong {
                        margin-top: 15px !important;
                    }
                }
            
                > ol {
                    line-height: 1.3rem;
                    margin-bottom: 1rem;
                    margin-left: 0;
                    counter-reset: clause;
                    list-style-type: none !important;
            
                    > li {
            
                        counter-increment: clause;
                        margin-bottom: 1rem;
                        position: relative;
                        padding-left: 50px;
            
                        &:before {
                            content: counter(section) "." counters(clause, ".") ". ";
                            position: absolute;
                            top: 0;
                            left: 0;
                        }

                        
                    }
            
                    ol[type=a] {
                        list-style-type: lower-alpha;
                        margin-left: 0px !important;
                        margin-top: 1rem;
                        li {
                            margin-bottom: 1rem;
                        }
                    }
            
                    ol[type=i] {
                        list-style-type: lower-roman;
                        margin-left: 20px;
                        margin-top: 1rem;
                        li {
                            margin-bottom: 1rem;
                        }
                    }
            
                    table {
                        margin-top: 1rem;
                        th {
                            font-weight: 500;
                        }
                        th, td {
                            border: 1px solid #666;
                            padding: 4px 8px;
                        }
                    }
            
                }
            }
        }
    }

    .bulletinArticlesPage.printing {
        .printarea * {
            box-sizing: border-box;
        }

        h2 {
            margin-top: 1rem !important;
        }

        ul  {
            margin-top: 0!important;
            margin-bottom: 1rem!important;
            padding-left: 2rem!important;
            list-style-type: disc !important;
        }

        li {
            display: list-item !important;
        }

        strong {
            display: inline !important;
        }

        .bulletinArticleModal {
            border: 0 none!important;
            background: none!important;
            max-width: 100%!important;
            height: auto!important;
            width: auto!important;
            position: initial!important;
            box-shadow: none!important;
            border-radius: 0!important;
            box-sizing: border-box;
        }

        .ui-dialog-content {
            overflow-x: unset !important;
        }

        .ui-dialog .ui-dialog-buttonpane {
            display: none!important;
        }

        ul {
            list-style: disc outside none!important;

            li {
                width: 100%!important;
                padding-left: 20px!important;
                list-style: disc outside none!important;
            }
        }

        button {
            display: none!important;
        }
    }
}