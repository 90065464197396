// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Noto+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap');

// This is a branding file only, do not add any structure or utilities
@import "./ele2023.scss";
 
// Import the colours
@import "./../src/2023rebrand/base-colours.scss";
// Tooltips
@import "global/tooltip";
// Nav bar
@import "./../src/2023rebrand/components/navigation.scss";
// Warnings and interupts
@import "./../src/2023rebrand/components/warning.scss";
// Banners
@import "./../src/2023rebrand/components/banner.scss";
// Boxes
@import "./../src/2023rebrand/components/box.scss";
// Forms
@import "./../src/2023rebrand/components/forms.scss";
//Lawguide
@import "./../src/2023rebrand/components/lawguide.scss";
//Notification
@import "./../src/2023rebrand/components/notification.scss";
// Products
@import "./../src/2023rebrand/components/subsiteProducts.scss";
// Contact
@import "./../src/2023rebrand/components/contact.scss";
// Faqs
@import "./../src/2023rebrand/components/faqs.scss";
//Documents
@import "./../src/2023rebrand/components/myDocuments.scss";
// Basket
@import "./../src/2023rebrand/components/basket.scss";
// Modal
@import "./../src/2023rebrand/components/modal.scss";
// Login
@import "./../src/2023rebrand/components/login.scss";
//BulletinArticles
@import "./../src/2023rebrand/components/bulletinArticles.scss";
//Search Form
@import "./../src/2023rebrand/components/searchForm.scss";
// EVMs
@import "./../src/2023rebrand/components/evm.scss";
//OBI
@import "./../src/2023rebrand/components/obi.scss";
//SVG styling
@import "./../src/2023rebrand/components/svg.scss";
//Print
@import "./../src/2023rebrand/components/print.scss";
//Before we continue
@import "./../src/2023rebrand/components/beforeWeContinue.scss";
//LegalLandscape
@import "./../src/2023rebrand/components/landscape.scss";
//Legal Health Checkup
@import "./../src/2023rebrand/components/legalhealthcheckup.scss";
//Ask a legal question
@import "./../src/2023rebrand/components/aalq.scss"; 
// NLRF review modal
@import "./../src/2023rebrand/components/NLRFReviewModal.scss"; 
// Home page US
@import "./../src/2023rebrand/components/homepageUS.scss"; 
// Upload for review
@import "./../src/2023rebrand/components/uploadForReview.scss";  

html, body {
    font-size: var(--font-size-root);
    line-height: var(--font-line-height);
    font-family: var(--font-family);
}

body {
    //padding-top: 100px;
    color: var(--dark-text-standard);
}

.btn, button, input[type=submit], .e_btn {
    padding: .4rem 2rem;
    border-radius: 40px;
    letter-spacing: var(--button-letter-spacing);
    background: var(--main-action-highlight);
    color: var(--light-text-standard);
    border: 0 none;
    font-size: 1rem;

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &:hover {
        background: var(--darker-action-hover-state);
        color: var(--light-text-standard);
        text-decoration: underline; 
    } 

    &:active {
        background: var(--main-action-hover-state);
        color: var(--light-text-standard);
    }     

    &.btn-primary {
        background: var(--main-action-highlight);

        &:hover {
            background: var(--darker-action-hover-state);
            color: var(--light-text-standard);
        }
    }

    &.btn-lg {
        padding: .8rem 2rem;
    }

    &.tooltipButton {
        border: none !important;
        padding: 0 !important;
        margin: 6px 0 0 0 !important;
        cursor: default !important;
        background: transparent url(/common/images/info_2023.png) no-repeat !important;
        height: 20px !important;
        width: 20px !important;
        box-shadow: none !important;
    }
}

h1 {
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
    letter-spacing: var(--font-h1-letter-spacing);
    color: var(--dark-text-high-emphasis);  

    &.title-1 {
        font-size: 4rem;
        font-weight: 700;
        line-height: 4.25rem;
        margin-bottom: 1.5rem;
        letter-spacing: var(--font-title-1-letter-spacing);
        color: var(--dark-text-high-emphasis);

        @media screen and (max-width: 380px) {
            font-size: 3rem;
        }
    }

    .emphasis {
        color: var(--secondary-main-colour);
        text-shadow: 1px 2px 0px black;
    }
}

h2 {
    color: var(--dark-text-high-emphasis);
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: var(--font-h3-letter-spacing);

    &.title-2 {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 3rem;
        margin-bottom: 1rem;
        letter-spacing: var(--font-h2-letter-spacing);  
        
        @media screen and (max-width: 37.5rem) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
    &.blue-title {
        color: var(--ui-primary, #5870F0);
   }
}

h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    letter-spacing: var(--font-h3-letter-spacing);
    color: var(--dark-text-high-emphasis);

    &.title-3 {
        color: var(--ui-primary, #5870F0);
        font-size: 3.5rem;
        margin-bottom: 1.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: 4rem; /* 85.714% */
        letter-spacing: -1.12px;
    }
}

h4 {
    font-size: 1.2rem;
    line-height: 1.875rem;
    font-weight: 600;
    letter-spacing: var(--font-h3-letter-spacing);
    color: var(--dark-text-high-emphasis);

    &.title-3 {
        color: var(--ui-primary, #5870F0);
        font-size: 3.5rem;
        margin-bottom: 1.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: 4rem; /* 85.714% */
        letter-spacing: -1.12px;
    }
}

p {
    &.steps {
        font-size: 0.75rem;
    }
}

caption {
    color: var(--dark-text-high-emphasis);
    font-weight: bold;
}

caption {
    color: var(--dark-text-high-emphasis);
    font-weight: bold;
}

.subtitle {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: var(--font-subtitle-letter-spacing);
    margin: 8px 0;
    

    &.about{
        font-weight: 600;
    }

    @media screen and (max-width: 37.5rem) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.error {
    color: var(--alert-error);
    text-align: left;
    font-size: 1rem;
    margin-top: 5px;
}

.success {
    color: var( --alert-success);
    text-align: left;
    font-size: 1rem;
    margin-top: 5px;
}

label {
    color: var(--dark-text-high-emphasis);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;

    &.error {
        font-weight: 400;
    }
}

.formGroup__inputContainer {
    margin: 16px 0;
    position: relative;
    text-align: left;
}

.formGroup__inputContainer--tooltip {

    button.tooltipButton { 
        position: absolute;
        top: 0.25rem;
        right: 0.5rem;
        opacity: 1;
        
    }
}

.feedback-container {
    margin-top: 56px;
    padding-bottom: 60px;

    .swiper {
        width: 100%;
        height: 340px;

        .swiper-wrapper {
            &:hover {
                cursor:grab;
            }
        }

        .swiper-slide {
            width: 40%;
            @media screen and (max-width: 43rem) {
                width: 100%;
            }
        }        
    }

    .feedback-box {
        padding: 40px;
        margin-top: 15px;

        @media screen and (max-width: 992px) {
            padding: 20px;
        }

        .fa-star {
            color: var(--icon-background);
        }

        .feedback-heading {
            color: var(--dark-text-high-emphasis);
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-top: 15px;
        }

        .feedback-content {
            color: var(--dark-text-medium-emphasis);
            font-family: var(--font-family-secondary);
            margin-top: .5rem;
        }

        .feedback-author {
            display: flex;
            margin-top: 16px;

            img {
                padding: 10px;
                background: var(--icon-passive-background);
                border-radius: 50%;
                height: 40px;
                width: 40px;
                color: var(--main-bg-color);
            }

            .feedback-author-name {
                margin-left: 8px;
                color: var(--dark-text-standard);
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 600;
                font-family: var(--font-family);
            }

            .feedback-author-title {
                font-size: 0.75rem;
                letter-spacing: var(--font-minor--detail-letter-spacing);
                color: var(--dark-text-medium-emphasis);
                margin-left: 8px;
                font-family: var(--font-family);
            }
        }
    }

    .swiper-slide-active {
        .feedback-box {
            padding: 60px;
            margin-top: 0px;

            @media screen and (max-width: 992px) {
                padding: 30px;
            }
        }
    }
}

footer {
    border-top: 20px solid var(--secondary-main-colour);
    .footer-nav {
        h4 {
            color: var(--epoq-primary-blue);
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1rem;
            letter-spacing: var(--font-non-h-header-letter-spacing);
            text-transform: uppercase;
        }

        a {
            text-decoration: none;
            color: var(--dark-text-high-emphasis);
            margin-top: 5px;
            &:hover {
                text-decoration-line: underline;
            }
        }
    }

    .footer-disclaimer {
        h4 {            
            font-size: 1rem;
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: var(--font-subtitle-letter-spacing);
        }     
        
        p {
            font-size: 0.875rem;
            line-height: 1.25rem;
            letter-spacing: var(--font-minor--detail-letter-spacing);
            color: var(--dark-text-medium-emphasis);
        }

        .copyright {
            color: var(--dark-text-high-emphasis); 
        }
    }
}

// custom content removal 
#nocodereg, .nav-divider:last-of-type {
    display: none!important;
}

.nav-pills {
    .nav-link:hover, .nav-link.active {
        background: none;
    }
}

.ele-registerForm, .ele-myAccountForm {
    .section {

        .regNextButton {
            align-self: flex-end;
        } 
        
        .formGroup--submit {
            text-align: right;
        }

        .questions {

            .formGroup {

                .flex-container {
                    display: flex;

                    select {
                        &:not(:last-of-type){ 
                            margin-right: 20px;
                        }
                    }
                }
            }

            .errorContainer {
                .error {
                    font-size: 0.9rem;
                    margin-bottom: 0rem;
                    margin-right: 20px;
                }
            }
        }
    }

    form {
        .section .questions {
            a {
                color: var(--dark-text-high-emphasis);
                text-decoration: underline;
                &:hover {
                    
                    color: var(--darker-action-hover-state);
                    text-decoration: none!important;
                }
            }
        }
    }

    #errorList {
        margin-bottom: 2rem;

        h4 {
            font-size: 1rem;
            font-weight: 700;
        }

        .error-element-link {
            font-size: 0.8rem;
            margin-bottom: 0rem;
        }
    }
}

.ele-regMyAccForm {

    .ChangePassword {
        display: none;
    }

}


.content-block-no-hero {
    margin-top: 3rem;
}

.e_jurisdictions  {
    font-size: 0.8em;
}

.jurFlag {
    padding: 0 0.5rem;
    background: var(--non-interactive-background-colour);
    display: inline-block;
    border-radius: 0.5rem;
    border: 1px solid var(--main-border-colour);
}

.claims-table {
    text-align: center;
    margin-top: 2rem;
  
    td, th {
      width: 50%;
    }
    
    ol {
      counter-reset: section;
  
      li {
        margin: 0.7rem 0;
        list-style-type: none;
        display: table;
  
        &::before {
          counter-increment: section;
          content: counter(section) ".";
          font-size: 1rem;
          font-weight: bold;
          display: table-cell;
          padding-right: 0.6rem;
        }
      }
    }
  }

.wistia_responsive_padding {
    width: 919.25px;
    height: 492.359px;
    padding: 45% 0 0 0 !important;
}



.Correspondence 
    #user_messages_container {
        text-align: left;
        border-top: 1px solid #ccc;
        padding-top: 20px;
        margin-top: 15px;

        .messageTools {
            padding-top: 15px;
            margin-bottom: 10px;

            .pagination {
                .pagination-navigation {
                    display: inline-block;
                    border: 1px solid #ccc;
                    border-radius: 6px;
                    padding: 5px;
                    line-height: 1em;
                }

                .pagination-text {
                    display: inline-block;
                }
            }

            .filterText {
                font-size: 12px;
                //text-align: left;
                margin: 6px 0;
            }  
        }

        #user_messages_items {
            overflow-y: scroll;
            max-height: 800px;
            overflow-x: hidden;
            padding-left: 10px;

            .active .message_info {
                background: #cde6f7;
                border: 1px dotted #321908;
            }
    
            .message_info {
                overflow: hidden;
                padding: 9px 0px;
                margin-top: 0px;
                border: 1px dotted #fff;
                border-bottom: 1px dotted #321908;
                cursor: pointer;

                .attachmentNotice {
                    position: inherit;
                    float: right;
                }
            }

            .message_title {
                font-weight: bold;
                //font-size: 13px;
            }

            .message_foreign_key.ticket {
                background: #d2d5f8;
                display: inline-block;
                color: #000;
                margin: 5px 0;
                padding: 5px 5px 5px 10px;
            }

            .message_time {
                font-size: 14px;
            }
        }

        .loading-spinners {
            min-height: 300px;
        }


        #user_message_detail_container {
            //padding-right: 29px;
            padding-top: 15px;

            .messageDetail--top {
                border-bottom: 1px solid #ccc;

                .messageDetail--emailFrom {
                    margin-right: 10px;
                    padding-right: 20px;
                    border-right: 1px solid #ccc;
                    font-size: 14px;
                    display: inline-block;
                }

                .messageDetail--emailTo {
                    margin-left: 10px;
                    font-size: 14px;
                    display: inline-block;
                }

                .messageDetail--emailSubject {
                    display: block;
                    font-size: 18px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    font-weight: bold;
                }

                .messageDetail--emailSent {
                    display: inline-block;
                    font-size: 12px;
                    margin-bottom: 5px;
                }

                .message_attachments {
                    margin-bottom: 15px;
        
                    a:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
        
                    .message_attachment {
                        padding: 5px 10px;
                        border: 1px solid #ccc;
                        font-size: 13px;
                        margin-top: 10px;
        
                        div {
                            display: inline-block;
                        }
        
                        .message_attachment_icon {
        
                            &.message_attachment_icon_pdf {
                                &::before {
                                    content: "\f1c1";
                                    font-family: "Font Awesome 6 Pro";
                                    margin-right: 10px;
                                    font-weight: 900;
                                    font-size: 13px;
                                    color: #FF2015;
                                }
                            }
                        }
                    }
                }
  
            }
            .message_text {
                text-align: left;
            }


        }
        

        

    }

.e_voucherForm {
    #schemeForm {
        padding: 20px;
        box-sizing: border-box;
        background: var(--non-interactive-background-colour);
        border-radius: var(--box-border-radius);

        .formGroup {
            label {
                display: block;
                text-align: left;
            }
            .formGroup__inputContainer {
                input#SchemeCode {
                    width: 100%;
                }

                button {
                    top: 3px;
                }
            }
        }

        .formGroup--submit {
            overflow: hidden;

            button#submitNewScheme {
                float: right;
            }
        }
    }
}

.ele-myAccount {
    .e_accountDetails {
        form#changePasswordForm {
            padding: 1.25rem;
            box-sizing: border-box;
            background: var(--non-interactive-background-colour);
            text-align: left;

            input {
                width: 100%;
            }

            .formGroup--buttonRow {
                margin-top: 1rem;
                overflow: hidden;

                a {
                    text-decoration: none;
                    float: left;
                }
                .formGroup--submit {
                    button  {
                        float: right
                    }
                }
            }
        }
    }
}

.e_body_search {

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }


    .full_span_bg {
        border-radius: 0.75rem;
    }

    .container {
        padding-right: 0;
        text-align: left;
    }

    #search-results-page {
        margin-top: 2.5rem;
    }

    .search {
        clear: both;
        overflow: hidden;
    
        .searchResults {
            position: relative;
            @media (min-width: 768px) {
                &.searchResults--articles {
                    width: 50%;
                    float: left;
                }
                &.searchResults--products {
                    width: 50%;
                    float: right;
                }
            }

            .searchResults-result {
                margin: 15px 0px;
                padding: 10px 10px 1px 20px;
                background: var(--non-interactive-background-colour);

                .searchResults-price {
                    display: none;
                }

                
                .jurFlags {
                    overflow: hidden;

                    .wrap_jurFlag {
                        overflow: hidden;

                        .jurFlag {
                            font-size: 0.75rem;
                            padding: 0 0.375rem;
                        }
                    }
                }
            }
    
            form { 
                margin-bottom: 1rem;
            }

            .searchResults-pagination a[disabled] {
                visibility: hidden;
            }
        }
    
        .searchResults-pagination {
            position: absolute;
            top: 0;
            right: 15px;
    
            .pagination-now {
                padding: 0 10px;
            }
    
            a[disabled] {
                pointer-events: none;
                cursor: default;
                text-decoration: none;
                color: #757575;
                :hover {
                    text-decoration: none;
                    color: #757575;
                }
            }
        }
    
    
    }
    

    form#searchJurisdiction {
        #jurisdictionSelectorWrapper {
            margin-bottom: 1.25rem;
        }
    }

    .search-box {
        background: var(--non-interactive-background-colour);
        padding: 1.5rem 2rem;
        border-radius: 0.75rem;
    }
}


.recommendation_image {
    text-align: center;
    margin: 0.625rem;
}

main {
    .container {
        // padding: 1.5rem 0;
        a {
            text-decoration: none;
        }

        .dpp_introduction {
            ol {
                margin-bottom: 1rem;
            }
        
            ol li {
                margin-bottom: 1rem;
            }
        
            p {
                margin-bottom: 1rem;
                line-height: 1.3rem;
            }
        }

        .dpp_body, .TOE_body, .cookie_notice {

            counter-reset: section;
        
            h2:before {
                counter-increment: section;
                content: counter(section) ". ";
                margin: 0 0.5rem 0 0;
            }
        
            > ol {
                line-height: 1.3rem;
                margin-bottom: 1rem;
                margin-left: 0;
                counter-reset: clause;
                list-style: none outside none;
        
                > li {
        
                    counter-increment: clause;
                    margin-bottom: 1rem;
                    position: relative;
                    padding-left: 50px;
        
                    &:before {
                        content: counter(section) "." counters(clause, ".") ". ";
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
        
                ol[type=a] {
                    list-style-type: lower-alpha;
                    margin-left: 20px;
                    margin-top: 1rem;
                    li {
                        margin-bottom: 1rem;
                    }
                }
        
                ol[type=i] {
                    list-style-type: lower-roman;
                    margin-left: 20px;
                    margin-top: 1rem;
                    li {
                        margin-bottom: 1rem;
                    }
                }
        
                table {
                    margin-top: 1rem;
                    th {
                        font-weight: 500;
                    }
                    th, td {
                        border: 1px solid #666;
                        padding: 4px 8px;
                    }
                }
        
            }
        }
    }
    .sra_badge {
        width: 275px;
        margin: 20px auto;
    }
}

.container.cookie_notice_page {
    padding: 1.5rem 0;
}

.container.terms {
    padding: 1.5rem 0;
}

.sub-page-banner-block {
    .box {
         display: flex;
         flex-direction: column;
    }
}

.e_latestbulletin {
    .e_bullets {
        a:hover {
            text-decoration: underline;
        }
    }
}