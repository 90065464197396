.basket {
    a {
        text-decoration: none;
    }

    .basketItem__product {
        text-align: left;
        

        .basketItem__services {
            margin-top: 1rem;

            ul {
                margin-top: 1rem;
            }
        }
    }

    .basketVoucher {
        display: none;
    }

    .basketProceed {
        margin-bottom: 2rem;
        float: right;
    }

    .paymentCards {
        float: left;

        .paymentCardsImage {
            text-align: left;
        }
    }
}

.ele-basketFirmRule2 {
    h1 {
        margin-top: 3rem;
    }

    .checkout-terms-rule2 {
        border: 1px solid var(--basket-border);
        padding: 0 1.5625rem;
        margin-bottom: 1.5625rem;
        height: 500px;
        overflow-y: auto;

        h1 {
            margin-top: 1rem;
        }

        a {
            text-decoration: none;
        }
    }

    .checkout-terms-accept {
        .checkboxContainer {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 15px;
            font-weight: bold;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          
            /* Hide the browser's default checkbox */
            .checkbox {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
          
              &:checked ~ .checkmark {
                background-color: var(--main-action-highlight);
              }
            }
          
            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 2px;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: var( --light-text-standard);
              border: 2px solid var(--main-border-colour);
          
              &:after {
                content: "";
                position: absolute;
                display: none;
              }
            }
          
          
            /* Show the checkmark when checked */
            .checkbox:checked ~ .checkmark:after {
              display: block;
            }
          
            /* Style the checkmark/indicator */
            .checkmark:after {
              left: 6px;
              top: 2px;
              width: 9px;
              height: 13px;
              border: solid var( --light-text-standard);
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

        .checkout-terms-buttons {
          float: right;
          clear: both;
        }
    }
}

.e_body_checkout_confirm {
    .ele-basketFinal {
            h1 {
                margin: 2rem 0;
            }
        .checkout-final {
            h1 {
                margin-top: 2rem;
            }
            .label {
                font-weight: bold;
            }
    
            .checkout-final-termsprivacy {
                button {
                    all: unset;
                    color: var(--main-action-highlight);
                    cursor: pointer;
                    &:hover {
                        color: var(--main-action-hover-state);
                    }
                }
            }
    
            .checkout-ordersummary {
                h2 {
                    display: none;
                }
    
                .checkout-customer {
                    .checkout-customer-address {
                        h3 {
                            margin: 1.5625rem 0;
                        }
                    }
                }
    
                .checkout-invoicenotice {
                    p {
                        background: var(--basket-invoice-note);
                        padding: 5px 10px;
                        display: inline-block;
                        margin-top: 10px;
                    }
                }
    
                .checkout-invoice {
                    .checkout-invoice-ref {
                        display: none;
                    }
    
                    .checkout-final-top {
                        text-align: right;
    
                        a {
                            text-decoration: none;
                        }
    
                        #btn-placeorder-top {
                            display: none;
                        }
                    }
                }
    
                .checkout-items--summary {
                    display: none;
                }
    
            }
    
            .checkout-ordersummary-details {
                    h2 {
                        margin: 1.5rem 0;
                    }

                    .checkout-items--details {
                        .checkout-items-servicelevel, 
                        .checkout-items-payment,
                        .checkout-items-total {
                            display: none;
                        }
                    }

                    .checkout-lawfirms {
                        .checkout-lawfirms-product {
                            h4 {
                                color: var(--dark-text-high-emphasis);
                                font-size: 1.5rem;
                                margin: 1.25rem 0;
                                font-weight: bold;
                            }
                            .checkout-lawfirm-detail-service {
                                display: none;
                            }
                            .checkout-lawfirm-detail-installments {
                                ul {
                                    margin: 20px 0 20px 40px;
                                    list-style-type: disc;
                                }
                            }
                        }
                    }
            }

            .checkout-final-bottom {
                text-align: right;
            }
        }
    } 
}

.checkoutComplete {

    .ele-orderComplete {
        h2 {
            margin-top: 4rem;
            margin-bottom: 2rem;
            font-size: 2rem;
        }

        h3 {
            margin: 2rem 0;
        }

        a {
            text-decoration: none;
        }
    }
}