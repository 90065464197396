

div.obi_header {
    text-align: center;
    margin-top: 20px;
}

div.obi_wrap  {
	width: 400px;
	margin: 0 auto;


    p {
        margin: 20px auto;
    }

    form {
        overflow: hidden;
        padding: 20px 20px 10px 20px;
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 10px;
    }
    form label {
        width: 60px;
        display: inline-block;
    }
    form div {
        float: left;
    }
    form button {
        float: right;
        margin-top: 10px;
    }
    
    input {
        width: 240px;
        margin: 2px 0;
        margin-left: 20px;
        border: 1px solid #999;
    }
    .error {
        color: red;
    }
    form p {
        width: auto;
    }
    form p.error {
        display: block;
        margin: 5px 0;
        margin-left: 75px;
        font-size: 0.7rem;
    }
    input.error {
        background: #fee;
        border: 1px solid red;
    }

}



