.bulletinArticlesPage {
    .container {
            p {
                text-align: left;
            }
        #bulletinSearchForm {
            padding: 1rem 1.25rem 1.25rem 1.25rem;
            background:var(--bulletin-search-form-background);
            box-sizing: border-box;
            border-radius: 0.375rem;
            margin-bottom: 1rem;

            text-align: left;

             .col {
                position: relative;
            }

            #searchClearance {
                position: absolute;
                right: 2.1875rem;
                top: 0.3125rem;
                font-size: 1.125rem;
            }
        }

        .pageSelection, .paginationTools  {
            margin-left: 1rem;
        }

        .text-right {
            text-align: right;
        }

        .bulletinArticles {
            display: flex;
            flex-wrap: wrap;

             .bulletinArticle {
                display: inline-block;
                margin: 1rem 0;
                position: relative;
                box-sizing: border-box;

                .box {
                    text-align: center;
                    text-decoration: none;
                    height: 100%;
                    position: relative;
                    padding-bottom: 2.5rem;
                    border-radius: 0.75rem;
                    display: inline-block;
                    color: var(--bulletin-body-text-no-link);
                    border: 0.125rem solid var(--bulletin-box-border-colour);
                    background: none;
                    width: 100%;
                    cursor: pointer;
                    

                        h2 {
                            font-size: 1.4em;
                            line-height: 1.6em;
                            text-decoration: none;
                            color: var(--bulletin-title);
                            margin: 1.5625rem 0; 
                        }

                        p {
                            font-weight: bold;
                            text-align: center;
                            margin-bottom: 1.5rem;
                        }

                        .date {
                            float: left;
                            font-size: .8em;
                            font-weight: bold;
                        }
                        .readTime {
                            font-size: .8em;
                            font-weight: bold;
                        }
                        .more {
                            display: inline-block;
                            padding: 0.3125rem 0.625rem;
                            border-radius: 0.375rem;
                            background: var(--main-action-highlight);
                            color: var(--light-text-standard);
                            float: right;
                        }

                        &:hover {
                            text-decoration: none;
                            color: var(--bulletin-body-text-no-link);
                            border: 0.125rem solid var(--light-text-standard);
                    
                            .more {
                              background:var(--main-action-hover-state);
                              color: var(--light-text-standard);
                            }
                          }


                    .bulletinImage {
                        opacity: .3;
                        filter: opacity(0.8);
                        position: absolute;
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 12px;
                        z-index: 0;

                        &.architecture {
                        background-image: url('/common/images/bulletins/architecture_B&W800x600.png');
                        }

                        &.diffusepattern {
                        background-image: url('/common/images/bulletins/diffusepattern_B&W800x600.png');
                        }

                        &.bench {
                        background-image: url('/common/images/bulletins/bench_B&W800x600.png');
                        }

                        &.bracken {
                        background-image: url('/common/images/bulletins/light&shadow_B&W800x600.jpg');
                        }

                        &.building {
                        background-image: url('/common/images/bulletins/building_B&W800x600.png');
                        }

                        &.clouds1 {
                        background-image: url('/common/images/bulletins/Clouds1_B&W800x600.png');
                        opacity: (0.5);
                        }

                        &.clouds2 {
                        background-image: url('/common/images/bulletins/Clouds2_B&W800x600.png');
                        }

                        &.frost {
                        background-image: url('/common/images/bulletins/frost_B&W800x600.png');
                        opacity: (0.4);
                        }

                        &.diffuse {
                        background-image: url('/common/images/bulletins/diffusepattern_B&W800x600.png');
                        }

                        &.ice {
                        background-image: url('/common/images/bulletins/ice_B&W800x600.png');
                        }

                        &.rooftop {
                        background-image: url('/common/images/bulletins/Rooftop_B&W800x600.png');
                        }

                        &.bubbles {
                        background-image: url('/common/images/bulletins/Bubbles_B&W800x600.png');
                        }

                        &.coronavirus1 {
                        background-image: url('/common/images/bulletins/coronavirus1_B&W800x600.png');
                        }

                        &.coronavirus2 {
                        background-image: url('/common/images/bulletins/coronavirus2_B&W800x600.png');
                        }

                        &.padlock {
                        background-image: url('/common/images/bulletins/padlock_B&W800x600.png');
                        }

                        &.UKHoP {
                        background-image: url('/common/images/bulletins/UKHoP_B&W800x600.png');
                        }

                        &.generic {
                        background-image: url('/common/images/bulletins/Generic_B&W800x600.png');
                        background-size: contain;
                        background-position: center;
                        background-color: #aaa;
                        }

                        &.safety {
                        background-image: url('/common/images/bulletins/safety_B&W800x600.png');
                        opacity: (0.2);
                        }

                        &.plane {
                        background-image: url('/common/images/bulletins/Plane_B&W800x600.png');
                        }

                        &.plane_outline {
                        background-image: url('/common/images/bulletins/Plane_outline800x600.png');
                        }

                        &.rails {
                        background-image: url('/common/images/bulletins/Rails_B&W800x600.jpg');
                        }

                        &.rails_colour {
                        background-image: url('/common/images/bulletins/Rails_colour800x600.jpg');
                        }

                        &.travel {
                        background-image: url('/common/images/bulletins/Travel_B&W800x600.jpg');
                        }

                        &.travel_colour {
                        background-image: url('/common/images/bulletins/Travel_colour800x600.jpg');
                        }

                        &.uk_mono {
                        background-image: url('/common/images/bulletins/UK_Mono800x600.png');
                        }

                        &.uk_greyscale {
                        background-image: url('/common/images/bulletins/UK_Greyscale800x600.png');
                        }

                        &.money {
                        background-image: url('/common/images/bulletins/money_B&W800x600.jpg');
                        }

                        &.uk_colour {
                        background-image: url('/common/images/bulletins/UK_colour800x600.jpg');
                        }

                        &.money_colour {
                        background-image: url('/common/images/bulletins/money_colour800x600.jpg');
                        }

                        &.coins {
                        background-image: url('/common/images/bulletins/coins_B&W800x600.png');
                        }

                        &.coins_colour {
                        background-image: url('/common/images/bulletins/coins_colour800x600.jpg');
                        }
                        
                        &.abstractpanels_colour {
                        background-image: url('/common/images/bulletins/abstractpanels_colour800x600.jpg');
                        }
                        
                        &.abstractpanels_greyscale {
                        background-image: url('/common/images/bulletins/abstractpanels_B&W800x600.png');
                        opacity: (0.2); 
                        }
                        &.architecture_colour {
                        background-image: url('/common/images/bulletins/architecture_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.bench_colour {
                        background-image: url('/common/images/bulletins/bench_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.bracken_BW {
                        background-image: url('/common/images/bulletins/bracken_B&W800x600.png');
                        opacity: (0.2);
                        }

                        &.bracken_colour {
                        background-image: url('/common/images/bulletins/bracken_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.building_colour {
                        background-image: url('/common/images/bulletins/building_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.clouds1_colour {
                        background-image: url('/common/images/bulletins/Clouds1_colour800x600.png');
                        opacity: (0.2);
                        }

                        &.clouds2_colour {
                        background-image: url('/common/images/bulletins/Clouds2_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.coronavirus2_colour {
                        background-image: url('/common/images/bulletins/coronavirus2_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.frost_colour {
                        background-image: url('/common/images/bulletins/frost_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericblue_colour {
                        background-image: url('/common/images/bulletins/GenericBlue_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericgreen_colour {
                        background-image: url('/common/images/bulletins/GenericGreen_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericinverse_BW {
                        background-image: url('/common/images/bulletins/GenericInverse_B&W800x600.png');
                        opacity: (0.2);
                        }

                        &.genericorange_colour {
                        background-image: url('/common/images/bulletins/GenericOrangecolour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericoutline_BW {
                        background-image: url('/common/images/bulletins/GenericOutline_B&W800x600.png');
                        opacity: (0.2);
                        }

                        &.genericpink_colour {
                        background-image: url('/common/images/bulletins/GenericPinkcolour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericpurple_colour {
                        background-image: url('/common/images/bulletins/GenericPurplecolour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericred_colour {
                        background-image: url('/common/images/bulletins/GenericRedcolour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericturquoise_colour {
                        background-image: url('/common/images/bulletins/GenericTurquoisecolour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.genericyellow_colour {
                        background-image: url('/common/images/bulletins/GenericYellowcolour800x600.jpg');
                        opacity: (0.2);
                        }
                        
                        &.handshake_BW {
                        background-image: url('/common/images/bulletins/handshake_B&W800x600.png');
                        opacity: (0.2);
                        }

                        &.ice_colour {
                        background-image: url('/common/images/bulletins/ice_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.redbubbles_colour {
                        background-image: url('/common/images/bulletins/redbubbles_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.rooftop_colour {
                        background-image: url('/common/images/bulletins/Rooftop_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.safety_colour {
                        background-image: url('/common/images/bulletins/safety_colour800x600.jpg');
                        opacity: (0.2);
                        }

                        &.steelworks_BW {
                        background-image: url('/common/images/bulletins/steelworks_B&W800x600.png');
                        opacity: (0.2);
                        }

                        &.steelworks_colour {
                        background-image: url('/common/images/bulletins/steelworks_colour800x600.jpg');
                        opacity: (0.2);
                        } 
                        }

                        .bulletinFooter {
                            position: absolute;
                            bottom: 0.625rem;
                            width: 100%;
                            left: 0;
                            padding: 0 1.5625rem;
                        }
                }
            }
        }

    }
}

.ele-bulletin {
     text-align: left;

     .e_bullets {
        list-style: none;

        button {
            margin-top: 0.3125rem;
            min-width: 15.875rem;
            border-radius: 0.25rem;
        }
     }

}