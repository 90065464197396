.legalhealthcheckup {
    .checkup-logo {
        max-height: 200px;
        margin-top: 3rem;
    }
    .checkup-box svg {
        max-width: 100px;
        margin-right: 1rem;
    }
    .checkup-box {
        border: 3px solid var(--secondary-main-colour);
        border-radius: var(--box-border-radius);
        padding: 1rem;
        display: grid;
        align-content: space-between;
    }
}

.e_body_RLCheckup,
.e_body_HRCheckup,
.e_body_GBCheckup {
    .checkup-logo {
        max-height: 200px;
    }
    .e_selector .e_selector_tabs {
        display:flex ;
        justify-content: space-evenly;
        margin-top: 1rem;
        padding-top: 5px;


        .e_selector_tab.e_selector_tab_group.active {
            color: var(--secondary-main-colour);
            font-weight: 700;
        }

        .e_selector_tab {
            visibility: visible!important;
        }
    }
    .e_selector_item.e_selector_introduction {
        .e_selector_buttons {
            .e_selector_button_start {
                margin-bottom: 10px;
            }
        } 
    }
    .e_selector_questions {
        .e_selector_item.e_selector_question {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 1px solid #03035e22;
            .e_selector_question_content {
                // ul {
                //     padding-left: inherit;
                //     li {
                //         list-style-type: none;
                //     }
                // }
            }
        }
        .e_selector_buttons {
            padding-top: 1rem;
            padding-bottom: 5px;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
        .e_selector_answers {
            display: flex;
            .e_selector_input_container {
                padding: 0 1rem 1rem 1rem;
                input {
                    //margin-right: .5rem;
                    visibility: hidden;
                }
                label {
                    border: 4px solid var(--main-action-highlight);
                    border-radius: 8px;
                    padding: 5px 10px;
                    margin-right: 12px;
                    user-select: none;
                    &:hover {
                        color: var(--checkup-box-border);
                        cursor: pointer;
                    }
                }
                input:checked ~ label {
                    border: 4px solid var(--checkup-box-border);
                    text-decoration: underline;
                    color: var(--checkup-box-border);
                }
            }
            
            .e_selector_subquestions {
                table {
                    thead {
                        tr {
                            th {
                                padding-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .e_selector .e_selector_question .e_selector_question_content {
        max-width: 70%;
        text-align: center;
        ul {
            text-align: left;
        }
    }

    .col-md-3.col-sm-12.mt-5 {
        .container {
            border: 3px solid var(--checkup-box-border);
            border-radius: var(--box-border-radius);
        }
    }
    .health-checkup-page-link {
        float: right;
        margin: 0 0 0 0;
    }
    #e_selector_reset {
        margin: 10px 0 0 0;
    }
    .e_selector_results {
        
        .e_selector_item.e_selector_result {
            padding: 0;
            h2 {
                display: none;
                visibility: hidden;
            }
            a.e_selector_print {
                float: right;
                margin-bottom: 1rem;
                padding: .4rem 2rem;
                border-radius: 40px;
                letter-spacing: var(--button-letter-spacing);
                background: var(--main-action-highlight);
                color: var(--light-text-standard);
                border: 0 none;
                font-size: 1rem;
                &:hover {
                    background: var(--darker-action-hover-state);
                    text-decoration: underline;
                }
            }
        }
        .e_selector_result_content {
            .e_selector_healthcheck_result {
                background: var(--non-interactive-background-colour);
                padding: 20px;
                //margin-right: 20px;
                text-align: left;
                border-radius: var(--box-border-radius);
                padding-bottom: 20px;
                @media print {
                }
                display: grid;
                margin-bottom: 2rem;
                
                .checkup_product {
                    display: none;
                    visibility: hidden;
                    @media print {
                        display: none;
                        visibility: hidden;
                    }
                }
                .e_selector_healthcheck_result__productname {
                    order: 1;
                    
                }
                .e_selector_healthcheck_result__productgo {
                    display: none;
                    
                    @media print {
                        display: none;
                        visibility: hidden;
                    }
                    
                }
                .e_selector_healthcheck_result__productgo.disabled {
                    //order: 3;
                    display: block;

                    .button_disabled {
                        //float: right;
                        margin-bottom: 0;
                        //text-decoration: underline;
                        font-weight: 700;
                    }
                    
                    @media print {
                        display: none;
                        visibility: hidden;
                    }
                } 
                
                .e_selector_healthcheck_result__productdesc {
                    order: 2;
                    p {
                        margin-bottom: 10px;
                    }
                }
            }
            .e_selector_healthcheck_result__productname {
                a {
                    //text-decoration: underline;
                    color: var(--bs-link-color);
                    font-size: 1.2rem;
                    line-height: 1.875rem;
                    font-weight: 600;
                    letter-spacing: var(--font-h3-letter-spacing);
                    &:hover {
                        text-decoration: underline;
                        //color: var(--secondary-main-colour);
                    }
                    &:before { 
                        content:"\203A"; 
                        font-size: x-large;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
        .e_selector_result_link {
            background: var(--main-action-highlight);
            border: 0px solid var(--main-action-highlight);
            color: #fff;
            padding: .4rem 2rem;
            border-radius: 40px;
            text-decoration: none;
            &:hover {
                background: var(--darker-action-hover-state);
                text-decoration: underline;
            }
        }
        .e_selector_result {
            border-bottom: none;
            h3 {
                display: none;
            }
        }
        .e_selector_result {
            h3.results_title {
                display: block;
            }
        }
    }
    .only-for-printing {
        display: none;
        visibility: hidden;
        @media print {
            //display: block;
            visibility: visible;
        }
    }
    .results_header {
        display: flex;
        svg {
            visibility: visible;
            @media print {
                .colour0 {
                    fill: #000;
                }
            }
        }
    }
    #e_document_selector {
        #jurisdiction-container {
            #jurisdiction-inner-container {
                background: var(--non-interactive-background-colour);
                padding: 20px;
                text-align: left;
                border-radius: var(--box-border-radius);
                margin-bottom: 1rem;
                box-shadow: 1px 1px 5px 0px #000;
                
                .selector_label {
                    margin-bottom: 10px;
                }
                
                
            }
        
            #jurisdiction-inner-container.empty-select {
                display: none;
            }  
        }
    }
    .e_selector .e_selector_result .results_title {
        background: none;
        padding-left: 10px;
    }
}

.e_selector_GeneralBusinessCheckuphealthcheck {
    .e_selector_question .e_selector_question_content {
        min-width: 60%;
    }
}