#frm_NLFReview {
    font-size: 0.9rem;
    padding-right: 15px;

    .e_NLFReviewTOE {
        #modalRule2 {
            height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;
            border: 1px solid #ccc;
            padding: 20px;
            background: #fafbfb;
        }
        label {
            font-weight: normal;
        }
    }

    .e_NLFReviewForm {
        label {
            font-weight: normal;
            font-size: 0.9rem;
        }
        textarea {
            width: 100%;
            margin-top: 10px;
            padding: 10px;
            height: 115px;
            border: 1px solid #ccc;
            background: #fafbfb;
        }       
        #acceptRule2 {
            margin-right: 5px;
            position: relative;
            top: 2px;
        }
        .e_acceptTerms {
            background: #e8f1f0;
            margin: 20px 0px 20px 0px;
            border: 1px solid #ccc;
            padding: 10px 20px;

            .error {
                background: #f9eded;
                padding: 2px 10px;
                border: 1px solid red;
                font-size: 0.9rem;
            }
        }         
    }
}

#e_NLFRSubmit:disabled {
    &:after {
        content: "";
        display: inline-block;
        width: 1em; /* Adjust size as needed */
        height: 1em;
        border: 0.15em solid currentColor;
        border-radius: 50%;
        border-top-color: transparent;
        animation: spin 1s linear infinite; 
        position: relative;
        top: 2px; 
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}