.ele-subsiteProducts {
    .mainPageTitle {
        display: none;
    }
    .RapidocsAI_Subsite {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .e_object_desktop {
            @media (min-width: 1200px) {
                display: block;
                .e_title {
                    margin: 0 0 20px 0;
                }
            }
            @media (max-width: 1200px) {
                display: none;
            }
        
        }
    }
    .e_object_mobile {
        @media (max-width: 1200px) {
            display: block;
        }
        @media (min-width: 1200px) {
            display: none;
        }
    }
    .e_product {
        //display: flex;
        width: 100%;
        margin-bottom: 2rem;
        a {
            text-decoration: none;
            color: black;
            background: none;

            &:hover {
                text-decoration: underline;
            }
        }
        .e_document_launch {
            @extend .btn;
            //float: right;

        }
        .e_block1 { //temp
            .e_readMore {
                display: none;
            }
        }
        .e_block2 { //temp
            .e_price {
                display: none;
            }
            .e_getStarted a {
                color: white;
                //margin-top: 1rem;
                display: inline-block;
            }
            .e_startDocument {
                display: flex;
                flex-direction: row-reverse;
            }
        }
        .e_block5 {
            .e_getStarted a {
                border: 0.0625rem solid var(--icon-border);
                background: var(--icon-color);
                color: var(--main-action-highlight);
                padding: 0.4rem 2rem;
                border-radius: 40px;
                letter-spacing: var(--button-letter-spacing);
                margin-right: 1rem;
                &:hover {
                    border-color: var(--main-action-highlight);
                }
            }
        }
    }
    .productBreadcrumb {
        padding: 1rem 0 1rem 0; 
        ;
    }

    #jurisdictionSelectorWrapper {
        .jurisdictionSelectorInner {
            display: flex;
            flex-direction: column;
        }

    }

    .category-menu-button {
        background-color: var(--light-text-standard);
        padding: 1rem;
        color: var(--dark-text-low-emphasis);
        border-radius: 0;

        &:hover {
            border-bottom: 2px solid var(--epoq-primary-blue);
            color: var(--epoq-primary-blue);
            text-decoration: none;
        }
    }
    .category-menu-button.active {
        border-bottom: 2px solid var(--epoq-primary-blue);
        color: var(--epoq-primary-blue);
        border-radius: 0;
    }
    .nav-pills {
        margin-bottom: 1rem;
        border-bottom: 1px solid var(--dark-text-low-emphasis);
    }
    .e_categoryTitle {display: none;}

    button.nav-item.category-menu-button {
        color: var(--dark-text-high-emphasis);
    }
}

.e_body_product {
    .e_serviceLevelInner {
        display: flex;
        justify-content: flex-end;
        .e_price {
            display: none;
            .value {
                display: none;
            }
        }
    }
    .ele-article {
        

        .e_document_launch {
            @extend .btn;
            //float: right;
        }
    }
}

.ele-product {
    margin: 1rem 0rem 1rem 0rem;
    .e_serviceLevels  {
        .e_name {
            display: none;
        }
    }
}