.navbar {
    background: var(--main-bg-color);
    padding: 8px 4px;
    border-radius: 16px;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem; 
    top: -18px;
    right: -14px;
    left: -16px;

    @media screen and (max-width: 37.5rem) {
        margin-top: .5rem;
        margin-left: .5rem;
        margin-right: .5rem;
        top: -4px;
        right: -4px;
        left: -4px;
    }

    @media screen and (max-width: 380px) {
        padding: 0;
        margin-left: 1rem;
        left: -13px;
        
    }

    .container-fluid.rounded {
        @media screen and (max-width: 380px) {
            flex-direction: column;
        }
    }

    .navbar-brand {
        --bs-navbar-brand-color: var(--light-text-standard);
    }

    .navbar-toggler {
        border: 0 none;

        .navbar-toggler-icon { 
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFFFFF' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
        }

        
    }

    .nav-user-toggle {
        color: var(--light-text-medium-emphasis);

        i {
            background: var(--light-text-high-emphasis);
            border-radius: 50%;
            color: #fff;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            margin-right: 10px;
            align-items: center;
        }

        &:hover {
            color: var(--light-text-standard);
        }

    }


    .offcanvas {
        --bs-offcanvas-bg: var(--main-bg-color);
    
        .nav-link {
            --bs-nav-link-color: var(--light-text-medium-emphasis);
            padding-bottom: .5rem;
            font-weight: 400;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-right: .5rem;
            margin-left: .5rem;

            &:not(.nav-user-toggle)i {
                padding-right: 0.5rem;
              }

            &:active {
                color: var(--light-text-standard);
                border-bottom: 1px solid var(--light-text-standard);
            }

            &:hover, &.show, &:focus {
                color: var(--secondary-main-colour);
            }
        }

        .offcanvas-body {
            .dropdown-menu {
                .dropdown-item {
                    text-align: initial;
                    color: var(--bs-link-color-rgb);
                    i {
                        padding-right: 0.5rem;
                      }

                    &:active {
                        background-color: var(--bs-dropdown-link-hover-bg);
                        filter: brightness(90%); 
                    }
                    &:hover {
                        color: var(--secondary-main-colour);
                    }
                }
            }
        }
        

        .navbar-toggler {
            --bs-navbar-toggler-border-color: var(--light-text-standard);        
            border: 0 none;
        }
    }
    .search-link-high-res {
        @media screen and (max-width: 75rem) { 
            display: none!important;
        }
    }

    .search-link-low-res {
        @media screen and (min-width: 75rem) { 
            display: none!important;
        }
    }

    .user-name {
        display: flex;
        gap: 3px;
        align-items: center;
    }

    .offcanvas-end {
        button.btn-close {
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
            background-color: var(--main-action-highlight);
            &:hover {
                background-color: var( --main-action-hover-state);
            }
        }
    }
}

.searchResults {
    .btn-pagination-next, .btn-pagination-prev {
        color: var(--bs-link-color);
    }
    .searchResults-list {
        .searchResults-result {
            a {
                color: var(--bs-link-color);
            }
        }
    }
}

.nav-link:focus {
    color: var(--main-action-highlight);
}

.bulletinArticlesPage .business-bulletin a:hover {
    text-decoration: underline;
}

