.before-we-continue {
   
    #progress-box {
  
      .statusContainer {
        display: inline-block;
  
        .status {
          border: 1px solid #A93835;
          margin-right: 20px;
          padding-right: 20px;
          background: #fff;
  
          &:before {
            background: #A93835;
            padding: 2px 10px;
            margin-right: 15px;
            content: "\f00d";
            font-family: "Font Awesome 6 Pro";
            font-weight: 700;
            display: inline-block;
            color: #fff;
          }
  
          p {
            margin: 0;
            padding: 0;
            display: inline-block;
            color: #A93835;
          }
  
          &.inProgress {
            border: 1px solid #037FAD;
  
            &:before {
              content: "\f111";
              background-color: #037FAD;
            }
  
            p {
              color: #037FAD;
            }
          }
  
          &.resolved {
            border: 1px solid #47800f;
  
            &:before {
              content: "\f00c";
              background-color: #47800f;
            }
  
            p {
              color: #47800f;
            }
          }
        }
      }
      
    }
  
    .forceChangeWeakPasswordContainer {
      margin-top: 20px;
  
      input[type="password"]{
        width: 100%;
      }
  
      h2, h1, .openingMessage {
          display: none;
      }
    }
  
    .NACAcceptance {
        .e_nav {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    #action-box {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px 20px;

      .e_acceptTermsCheckboxError {
        display: none;
        top: 2px;
        position: relative;
      }
  
      .region_aft_selection {
        margin: 20px 0;
      }
  
      .legals {
        margin-top: 20px;
      }
  
      .TOUAcceptanceContent, .fullCookieNotice {
        max-height: 400px;
        overflow: hidden;
        overflow-y: scroll;
        margin-top: 10px;
        padding: 0 25px 20px 25px;
        border: 1px solid #ccc;
      }
  
      .e_acceptTerms {
        margin-top: 10px;
        margin-left: 26px;
      }
  
      .e_continue {
        text-align: right;
      }
  
      input[type=checkbox].css-checkbox {
        position:absolute; z-index:-1000; left:-1000px; overflow: hidden; clip: rect(0 0 0 0); height:1px; width:1px; margin:-1px; padding:0; border:0;
      }
  
      input[type=checkbox].css-checkbox + label.css-label {
        padding-left:45px;
        height:30px;
        display:inline-block;
        line-height:30px;
        background-repeat:no-repeat;
        background-position: 0 0;
        font-size:18px;
        vertical-align:middle;
        cursor:pointer;
      }
  
      .e_acceptTermsCheckboxError {
        color: #A93835;
        font-size: 16px;
        margin-left: 20px;
      }
  
      .messages {
        text-align: center;
        margin-top: 30px;
        font-size: 17px;
      }
  
      input[type=checkbox].css-checkbox:checked + label.css-label {
        background-position: 0 -30px;
      }
      label.css-label {
        background-image: url("/common/images/csscheckbox.png");
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
  
      #cookieBox {
        right: 0;
        max-width: 100%;
        background: #fff;
        position: initial;
        width: 100%;
        display: block!important;
  
        #cookieContent {
          padding: 0;
  
          .moreDetails {
            display: none;
          }
        }
  
      }
  
    }
  
}