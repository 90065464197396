.form {
    padding: 0 4px;

    #errorList {
        .error-element-link {
            cursor: pointer;
        }
    }
}

.ele-registerForm, .ele-myAccountForm {
    .nav-pills {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.125rem;

        @include media-breakpoint-down(lg){
            align-items: flex-start;
            justify-content: flex-start;
        }

        .nav-divider {
            flex: 1;
            background: var(--icon-passive-background);
            background-repeat: repeat-x;
            background-position: center left;
            height: 2px;
            margin: 0 20px;

            @include media-breakpoint-down(lg){
                display: none;
            }
        }

        .nav-item {
            margin-left: 15px;

            .nav-link {
                display: flex;
                align-items: center;

                .nav-pills-index {
                    background: var(--icon-passive-background);
                    color: var(--icon-color);
                    border-radius: 50%;
                    width: 40px; 
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.25rem;
                }                

                &.active {
                    .nav-pills-index { 
                        background: var(--icon-background-non-interactive);
                    }
                }
    
                &.invalid {
                    .nav-pills-index { 
                        background: var(--alert-error);
                    }
                }
    
                &.complete {
                    .nav-pills-index { 
                        //background: var(--alert-success);
                        background: url('/common/ele/images/icons/checkmark.png') no-repeat;
                        font-size: 0rem;
                    }                
                }                
            }

            &:firstt-of-type{
                margin-left: 0;
            }

            &:last-of-type{
                background: none;
            }
   
            .nav-pills-title {
                font-size: .875rem;
                color: var(--dark-text-high-emphasis);
                line-height: 1.25rem;
                letter-spacing: var(--font-minor--detail-letter-spacing);
                background: #fff;
                padding: 0 10px;
            }
        }
    }

    .section {
        display: flex;
        flex-direction: column;     

        .questions {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;

            .form-imported {
                p {
                    margin: 10px 0;
                }
            }
            
            .CustomerBulletinAccepted {
                .formGroup__inputContainer {
                    margin-top: 0;
                }
            }

            .PostcodeWidget {
                .formGroup {                    
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;

                    label {
                        width: 100%;
                    }

                    div {
                        margin-right: 15px;
                    }
                }
            }
        }

        .error {
            border-color: var(--alert-error);
        }
    }
}

.bulletin_GDPR_log, .BulletinTypeId {
    display: none!important;
}

.formGroup__inputContainer--showhide .show-hide-toggle {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.3125rem 0.75rem;
    width: 2.625rem;
    height: 2.1875rem;
}

.ele-login {
    
    .e_forgottenPassword, .e_forgottenUsername {
        a {
            text-decoration: none;
        }
    }
}


.ele-forgottenPassword {
    .formGroup, li {
         text-align: left;
    }
}


.feedback_form {
    .e_customerFeedbackForm {
        .e_feedbackForm {
            #customerFeedbackForm {
                .e_questions {
                    ul {
                        list-style-type: none;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        ul {
                            list-style-type: none;

                        }
                    }
                    .e_radio {
                        .e_answers {
                            .e_answer label {
                                width: 100px;
                                color: black;
                            }
                        }
                    }
                    .e_item.e_textarea {
                        .e_question {
                            .e_label {
                                color: black;
                            }
                        }
                    }
                }
            }
        }
        .feedback_return_home {
            display: inline-block;
            margin-top: 20px;
        }
    }
    .e_wrapper {
        .content-block {
            .e_title {
                float: left;
            }
        }
    }
    .pageContainer {
        main {
            min-height: 70vh;
        }
    }
}

#clm_completeMessage {
    margin: 60px 20px;
}

.complex_image {
    flex: 1 0 20%;

    @include media-breakpoint-down(xl){
        flex: 1 0 30%;
    }

    @include media-breakpoint-down(lg){
        background-size: 250px;
    }

    @include media-breakpoint-down(md){
        display: none; 

    }

    background: url(/common/images/Make\ a\ claim_1.svg);
    background-repeat: no-repeat;
    background-size: 300px;
}

.complex_text {
    flex: 1 0 60%;

    @include media-breakpoint-down(lg){
        flex: 1 0 50%;
    }
}