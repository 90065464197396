#searchForm {
    .searchFormFields {
        display: flex;
        flex-direction: row; 

        @media screen and (max-width: 650px) {
            flex-direction: column; 

            .searchFormField {
                margin-left: 0;
            }
        }

        .searchFormField {
            margin-left: 10px;

            .input-container {
                position: relative;

                .form-control {
                    padding-right: 30px;
                }

                .clear-icon {
                    position: absolute;
                    top: 50%;
                    right: 10px; /* Adjust based on your design */
                    transform: translateY(-50%);
                    cursor: pointer;
                  }
              }


            @media screen and (max-width: 650px) {
                margin: 10px 0;
            }
             
            &:first-of-type {
                flex-grow: 1;
                margin-left: 0
            }      
            
            label {
                width: 100%;
                margin-bottom: 7px;
                font-size: initial;
            }
        }

        .searchFormField_Submit {
            display: flex;

            button {
                align-self: flex-end;
                @media screen and (max-width: 650px) {
                    width: 100%;
                }

                i {
                    margin-right: 7px;
                }

                @media screen and (max-width: 780px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            @media screen and (max-width: 650px) {
                margin-top: 10px;
            }
        }    
        
        .jurisdictionSelectorInner {
            display: flex;
            flex-direction: column;
        }

        #jurisdictionSelectorWrapper label {
            font-size: initial; 
        }
    }
}