.ui-dialog {
    background: var(--light-text-standard);
    padding: 10px 20px 20px 20px;
    border-radius: 12px;
    z-index: 10501;

    .ui-dialog-titlebar {
      .ui-dialog-title {
        display: none !important;
    }

      .docHelp {
        border: 0.0625rem solid var(--icon-border);
        background: var(--icon-color);
        color: var(--main-action-highlight);
          &:hover {
            border-radius: 40px;
            border: 1px solid var(--icon-background);
          }
      }
    }
}

.ui-dialog .ui-dialog-titlebar {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.5rem;
    margin: 0 -0.5rem;
}

#document-folder-modal .field select {
  width: 100%!important;
}

.ui-dialog .ui-dialog-buttonpane {
    margin-top: 1rem;
}

.ui-dialog .ui-dialog-titlebar-close {
    font-size: 1rem;
    line-height: 1rem;
    text-indent: 0;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    button {
        padding: 0.2rem 1rem;
    }
}

.ui-dialog-content {
  overflow-x: hidden;
  margin-top: 1rem;
  padding: 1rem 0;
    form#modalForm input.e_modalText {
    width: 100%;
  }
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}
.ui-helper-clearfix:after {
	clear: both;
}



.ui-dialog-titlebar-close {
    &:after {
      content: "Close";
      position: unset;
      margin: 0 7px;
      font-size: 1rem;
    }
  }

  .ui-dialog-buttonset {  
      .e_cancel {
        &:after {
          font-family: "Font Awesome 6 Pro";
          content: "\f00d";
          margin-left: 10px;
        }
      }
    
      .e_ok {
        &:after {
          font-family: "Font Awesome 6 Pro";
          content: "\f00c";
          margin-left: 10px;
        }
      }
  }

  .ui-widget-overlay {
    background: #000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: .4;

    &:not(:first-child){
      z-index: 10500;
    }
}


button#document-share-invite-btn {
  margin: 1rem 0;
}

#document-share-modal .label_checkbox input[type=checkbox] {
  margin-right: 10px;
}

.ui-datepicker {
	border: 1px solid #ccc;
	box-shadow: 4px 4px 8px rgba(0,0,0,0.1);
	padding: 10px 15px 15px;
	width: 280px;
	background: #fff;
	text-align: center;
	display: none;
  a {
    text-decoration: none;
  }
}
.ui-datepicker-title {
  margin-bottom: 10px;
}
.ui-datepicker-header .ui-state-disabled {
  color: #eee;
  text-decoration: none;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  cursor: pointer;
}
.ui-datepicker-prev {
  float: left;
  width: 45px;
  text-align: left;
}
.ui-datepicker-next {
  float: right;
  width: 45px;
  text-align: right;
}
.ui-datepicker table {
  width: 100%;
  margin-bottom: 0;
}
.ui-datepicker th,
.ui-datepicker td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 30px;
}
.ui-datepicker th{
  padding: .4em;
}
.ui-datepicker td{
  padding: 0;
}
.ui-datepicker td a{
    display:block;
    padding: .4em;
    &:hover{
      color: #FFFFFF;
      text-decoration: none;
      background-color: #369;
    }
}
.ui-datepicker td a:hover{
color: #FFFFFF;
text-decoration: none;
background-color: #369;
}
.ui-datepicker-today {
  background: #eef4ff;
}
td.ui-datepicker-unselectable {
  color: #ddd;
  padding: .4em;
}

#document-folder-modal #document-folder-id {
    padding: 10px;
    font-size: 1rem;
}