.login-page {
    .e_login {
        #mainLoginForm {
            .e_forgottenPassword, .e_forgottenUsername {
                float: right;
                padding-bottom: 1rem;
                margin-top: 0!important;
            }
            button {
                margin-top: 1rem;
                width: 100%;
            }
            .e_forgottenPassword a, .e_forgottenUsername a{
                text-decoration: none!important;

                &:hover {
                    text-decoration: underline!important;
                }
            }
            
        }
        #registerNowForm {
            button {
                margin-top: 1rem;
                width: 100%;
    
                background: none;
                color: var(--bs-btn-active-bg);
                text-decoration: none;

                &:hover {
                    text-decoration: underline!important;
                }
            }
            
        } 
    }
    .login-box {
        margin: 10rem 25rem 5rem 25rem!important;
        @media screen and (max-width: 1000px) {
            margin: 11rem 2rem 1rem 2rem!important;
        }
    }
}

.ele-registerForm .nav-pills .nav-item .nav-link .nav-pills-title {
    &:hover {
        text-decoration: underline;
    }
}

.ele-registerForm form a {
    &:hover {
        text-decoration: underline;
    }
}