
.ele-article {
    a {
        text-decoration: none;
        color: var(--bs-link-color);
        &:hover {
            text-decoration: underline;
        }
    }

    .lawguideContainer {
        .head {
            h6 {
                font-size: 1.125rem;
                font-weight: bold;
            }
        }
    }

    h2 {
        line-height: var(--font-line-height);
        margin: 1rem 0;
    }

    h3 {
        font-size: 1.25rem;
    }

    .btn {
        margin-bottom: 3rem;
        a {
            color: var( --light-text-standard)
        }
    }

    .e_article_bottom {
        margin-top: 1.875rem;
        padding: 1.875rem 0;
        border-top: 0.125rem solid var(  --grey-scale-02);

        #e_relatedServices {

            #jurisdictionSelectorWrapper {
                display: none;
            }

            .e_products {
                padding-top: 2rem;
                margin-left: 0;
                list-style-type: none;
                display: flex;
                padding: 0.5rem 0;
                justify-content: center;
                gap: 0.75rem;
                flex-wrap: wrap;
                
            }

            .e_product {
                list-style-type: none;
                align-items: center;                
            
                a {
                    font-size: 0.875rem ;
                    padding: 4px 10px;
                    background: var(--header-on-image);
                    color: --secondary-main-colour;
                    border: 0.0625rem solid var(--secondary-main-colour);
                    border-radius: 1rem;
                }

                .e_price, .e_info, .jurFlags {
                    display: none;
                }
            }
        }
    }


    .ele-item-article {
        ul {
            margin-bottom: 1rem;
            list-style: disc;
            margin-left: 1.25rem;
        
            li {
                list-style-type: disc;
                margin-left: 1.5625rem;
                line-height: 2rem;
            }
        }
        .toc ul li {
            list-style-type: none;
        }
    
    }

    .lawguide {
        width: 95%;
        overflow: auto;
        
        ul {
            list-style: none !important;
            padding-left: 0.5rem;
        }

        a {
        display: block;
        padding: 0.25rem 1rem;
        font-size: 0.9rem;
        }

        a.selected,	a.open {
        font-weight: bold;
        position:relative;
        text-decoration: underline;
        }
        
        // a.open {
        //     border-left: 3px solid #369;
        // }

        .level3 a {
        padding-left:1.5rem;
        cursor: pointer;
        &:before { display: none; }
        }

        .level4 a {
            padding-left:2rem;
            cursor: pointer;
            &:before { display: none; }
		}

        UL.childrenlevel3 { display: none; }
        UL.childrenlevel4 { display: none; }
        UL.childrenlevel5 { display: none; }
        UL.open { display: block; }
      }

      .toc {
        ul {
            list-style: none;
            padding-left: 1rem;
        }
      }

      span.lawguide_tooltip {
        display: inline;
        opacity: 1;
        font-size:1rem ;
      }

      .tabledefault  {
        border-collapse: collapse;
        width: 100%;
        border-bottom: 1px solid #ddd;
            th,td {
                padding: 8px;
                text-align: left;
                border-bottom: 1px solid #ddd;
            }
      }
}

.ele-item-flex {
    display: flex;
    flex-direction: column;
}

