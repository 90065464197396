:root {
    --font-size-root: 16px;
    --font-line-height: 24px;
    --font-family: 'Noto Sans', sans-serif;
    --font-family-secondary: 'Inter', sans-serif;
    --font-title-1-letter-spacing: -2.56px;
    --font-h1-letter-spacing: -1.36px;
    --font-h2-letter-spacing: -0.8px;
    --font-h3-letter-spacing: -0.96px;
    --font-non-h-header-letter-spacing: -0.18px;    
    --font-subtitle-letter-spacing: -0.4px;
    --font-minor--detail-letter-spacing: 0.048px;

    --light-text-standard: #FFFFFF;
    --header-on-image: #FFFFFF;
    --header-in-box: #2f2f2f;
    --header-highlight: #5870F0;
    --light-text-medium-emphasis: rgba(255, 255, 255, 0.60);
    --light-text-high-emphasis: rgba(255, 255, 255, 0.87);
    --dark-text-standard: #666666;
    --dark-text-high-emphasis: #212121;
    --dark-text-medium-emphasis: #666;
    --dark-text-low-emphasis: #9E9E9E;
    --epoq-primary-blue: #03035E;
    --alert-error: #E93334;
    --alert-success: #0C7C59;
    --grey-scale-02: #DDD;
    --grey-scale-03: #E7E7E7;
    --grey-scale-04: #d3d3d3;
    --AALQHighlight: #008aed;

    --icon-main-colour: #5870F0;
    --icon-secondary-colour: #000000;

    --assembler-progress-colour: #ffffff;

    --icon-background: #5870F0;
    --icon-border: #CFCFE6;
    --icon-background-non-interactive: var(--epoq-primary-blue);
    --icon-color: #FFF;

    --secondary-main-colour: #AFB5E7;
    --main-bg-color: #272727;
    --main-action-highlight: #5870F0;
    --main-action-hover-state: #445CDC;
    --main-form-label: #000000;
    --darker-action-hover-state:#3146A4;
    --bs-btn-active-bg: #5870F0;
    --bs-btn-active-color: #FFF;
    --bs-link-color: #030376; 
    --overlay-colour: rgba(0,0,0,.4);
    --non-interactive-background-colour: #F1F1F1;
    --icon-passive-background: #dddddd;
    --documents-old-name: #3B3A3A;

    --main-border-colour: #ccc;
    --highlight-border-colour: #5870F0;

    --button-letter-spacing: 0.2px;

    --box-border-radius: 4px;
    --box-border-radius-2: 5px;
    --standard-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);

    --content-block-dark-background: linear-gradient(112deg, #212529 0%, rgba(73, 80, 87, 0.00) 100%), #495057;
    --box-dark-background:linear-gradient(150deg, rgba(145, 159, 235, 0.20) 0%, rgba(145, 159, 235, 0.08) 100%);

    --bulletin-search-form-background: #eee;
    --bulletin-title:#0c3c60;
    --bulletin-body-text-no-link: #000A05;
    --bulletin-box-border: #E1F0E1;
    --bulletin-borders: #438E63;
    --bulletin-button-mouse: #54AFA3;
    --bulletin-button-border: #03035e;
    --bulletin-box-border-colour: #5870f0bf;

    --basket-border: #e2ecf9;
    --basket-invoice-note: #efefef;

    --documents-folder-background: #ccd6fd;
    --documents-folder-border:#94a8f9;
    --border-radius: 40px;

    --landscape-image-background: rgba(15, 126, 175, .7);
    --landscape-image-background-hover: rgba(15, 126, 175, .6);
    --landscapr-image-highlight: #fff;

    --checkup-box-border: #00d3ff;
}