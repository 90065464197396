.faq-navigation {
    .nav {
        .nav-link {
            color: var(--dark-text-high-emphasis);
        }
        .nav-link.active {
            color: var(--epoq-primary-blue);
            border-color: var(--epoq-primary-blue) ;
        }
    }
    .box {
        cursor: pointer;
    }
    .tab-pane-force-reveal {  
        display: block !important;
        opacity: 1 !important; 
    }    
}

.faq-icon {
    button {
        padding: 0;
        background: none;
        color: var(--header-highlight);

        &:hover {
            color: var(--header-highlight);
            background: none ;
        }
    }
}

.faq-ul {
    border-bottom: 0.125rem solid var(--grey-scale-03);
    margin-bottom: 1rem;
}

button.faq-li {
    border-bottom: 0.125rem solid var(--grey-scale-03);
    border-radius: 0 !important;
    position: relative;
    top: 0.125rem;
}

.faq-navigation .box-container .box {

    .box-header {
        margin-bottom: 0;
    }

    &:hover {
        .faq_question  {
            cursor: pointer;
            color: var(--darker-action-hover-state);
            text-decoration: underline;
        }

        .faq-icon button {
            color: var(--darker-action-hover-state);
        }        
    }    
}


    .faq-header {
        .faq_question  {
            color: var(--main-action-highlight);
            font-weight: bold;
            margin: 0;      
        }
    }

h1.title-2 {
    margin-bottom: 0.5rem;
}

p.subtitle {
    margin: 0;
}

