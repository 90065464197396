// This is a functional file only, do not add any branding

// Import Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap"; 

.sHidden {
    display: none!important;
}

.tab-pane-force-reveal {  
    display: block !important;
    opacity: 1 !important; 
}