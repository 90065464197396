.upload_for_review_body {
 
    .messageFromServer {
        color: red;
        border: 1px solid red;
        padding: 15px 10px 0px 15px;
        margin-bottom: 20px;    
    }
}

.upload-for-review .introduction {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

footer {
    margin-top: 100px;
}
