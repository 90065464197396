.banner-container {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.8) 43.26%, rgba(0, 0, 0, 0.4) 80.88%), url('/common/ele/images/BRPUK_bg_1.jpg'), lightgray 50% / cover no-repeat;
    margin-top: -100px;
    height: 900px;
    max-height: 100vh;
    background-size: cover;
    align-items: center; 
    justify-content: center;
    text-align: center;
    display: flex;
    border-bottom: 20px solid var(--secondary-main-colour);

    .banner-container-inner {
        justify-content: center;

        .banner-width-restriction {
            max-width: 812px;
        }

        h1 {
            color: var(--header-on-image);
        }

        p {
            color: var(--light-text-high-emphasis);
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 2rem;
        }
    }

    &.banner-container-sub-page {
        height: auto;
        padding-top: 9.875rem;
        padding-bottom: 40px;
        border-bottom: 20px solid var(--secondary-main-colour);

        .banner-container-inner {
            padding-bottom: 0;
            
            h1 {
                font-size: 2.125rem;
            }
        }
    }

    @media (max-height: 600px){
        max-height: none;
        padding-top: 150px;
        height: auto;

        .banner-container-inner {
            padding-bottom: 50px;
        }
    }
}

.banner-container-contact {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.15) 43.26%, rgba(0, 0, 0, 0.80) 80.88%), url('/common/ele/images/BRPUK_bg_1.jpg'), lightgray 50% / cover no-repeat;
    margin-top: -6.25rem;
    height: 12.875rem;
    max-height: 100vh; 
    background-size: cover;
    .epoq-logo-white {
        background: url('/common/ele/images/epoq-logo-white.png') no-repeat;
        height: 8.125rem;
        margin-left: auto;
        margin-right: auto;
        width: 20rem;
    }
    @media screen and (max-width: 45rem) {
        background: none;
        margin-top: 0;
        height: 0;
        max-height: none; 
        background-size: 0;
        border-bottom: 0;
    }
} 

.banner-container-faqs {
    background: url('/common/ele/images/contact-form-banner-brpuk.jpg'), lightgray 50% / cover no-repeat;
    margin-top: -6.25rem;
    height: 16.875rem;
    max-height: 100vh; 
    background-size: cover;
    border-bottom: 20px solid var(--secondary-main-colour);
    @media screen and (max-width: 45rem) {
        background: none;
        margin-top: 0;
        height: 0;
        max-height: none; 
        background-size: 0;
        border-bottom: 0;
    }
}

.banner-container-how-it-works {
    background: url('/common/ele/images/how_it_works_image.jpg'), lightgray 50% / cover no-repeat;
    margin-top: -6.25rem;
    height: 16.875rem;
    max-height: 100vh; 
    background-size: cover;
    border-bottom: 20px solid var(--secondary-main-colour);
    @media screen and (max-width: 45rem) {
        background: none;
        margin-top: 0;
        height: 0;
        max-height: none; 
        background-size: 0;
        border-bottom: 0;
    }
}

.login-page {
    height: 46.875rem;
    background-size: cover;
    margin-top: -6.25rem;
    @media screen and (max-width: 62.5rem) {
        background: none;
    }
    
    .title-1 {
        color: var(--header-in-box);
    }
}

.subsite-icons {
    font-size: 2rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 2rem;
}