.box {
    background: var(--non-interactive-background-colour);
    padding: 20px;
    margin-right: 20px;
    text-align: left;
    border-radius: var(--box-border-radius);

    &:last-child {
        margin-right: 0px;
    }

    .box-header {
        margin-bottom: 20px;

        .box-title {
            display: flex;
            align-items: center;
        }

        h3 {
            margin-bottom: 0;
        }
    }

    .box-action {
        .text-decoration-none {
            background: var(--main-action-highlight);
            color: var(--light-text-standard);
            padding: 8px;
            border-radius: var(--border-radius);
            display: block;
            
            &:hover {
                text-decoration: underline!important;
                background: var(--darker-action-hover-state);
                color: var(--light-text-standard);
            }
        }

    }
    
    .box-icon {
        background: var(--secondary-main-colour);
        border-radius: 50%;
        color: #fff;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        margin-right: 10px;
        flex: none;

        i {
            font-size: 1rem;
        }
    }

    .box-icon-large {
        background: var(--secondary-main-colour);
        border-radius: 50%;
        color: #fff;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        margin-right: 10px;
        flex: none;

        i {
            font-size: 1rem;
        }
    }

    .box-icon-image {
        //background:var(--icon-border);
        border-radius: 50%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        margin-right: 10px;   
        
        i {
            font-size: 2rem;
            background: none;
        }
    }   

    .lawguide-icon {
        background: var(--secondary-main-colour) !important;
        background: var(--icon-color) !important;
        font-size: 2rem;
        width: 100px !important;
        height: 100px !important;
        display: flex;
        align-items: center;

        i {
            font-size: 1.5rem;
            color: var(--secondary-main-colour);
        }
    }

    .box-content {
        .content-box {
            display: flex;
            padding: 0.5rem 0;
            align-items: flex-start;
            align-content: flex-start;
            gap: 0.75rem;
            align-self: stretch;
            flex-wrap: wrap;
        }
        
        .content-list-item {
            display: flex;
            padding: 0 0.5rem;
            align-items: center;
            border-radius: 1rem;
            border: 0.0625rem solid var(--grey-scale-02);
            border-color: var(--icon-border);
            background: var(--light-text-standard);
        
            a {
                text-decoration: none;
                font-size: 0.875rem ;
            }

            &:hover {
                border-color: var(--icon-background);
            }
        }
    }
}



.content-block {
    margin: 3rem 0;
    position: relative;
    overflow: hidden;
    text-align: center;

    &.title-spacing {
        margin: 4.5rem 0;
        @media screen and (max-width: 37.5rem) {
            margin: none;
        }
    }

    &.sub-page-banner-block {
        margin: 1.5rem 0;
    }

    .content-block-background-image {
        position: absolute;
        right: 1%;
        bottom: 12%;
        max-width: 50%;
        @media screen and (max-width: 484px) {
            bottom:40px
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .content-block-background-image-2 {
        position: absolute;
        right: 62%;
        bottom: 23%;
        max-width: 50%;
        @media screen and (max-width: 484px) {
            bottom:40px
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .box-wrapper-with-background {
        width: 70%;
    }

    .content-block-light {

        &.bordered {
            padding-left: 4.375rem;
            @media screen and (max-width: 43rem) {
                padding-left: 0;
            }

            &::before {
                content: "";
                position: absolute;
                top: -10px;  /* offset to align with the rounded edges */
                bottom: -10px; /* offset to align with the rounded edges */
                left: 0;
                width: 8px;
                background: var(--secondary-main-colour);
                border-radius: 10px; /* rounding only the appropriate corners */

                @media screen and (max-width: 43rem) {
                    content: none;
                }
            }   
        }

        .back-btn {
            a {
                color: var(--main-action-highlight);
                text-decoration: none;
                text-align: center;
                background-color: white;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.375rem; /* 137.5% */
                letter-spacing: 0.2px;
                text-transform: capitalize;
            }
        }
    }

    .content-block-dark {
        padding: 3rem;
        background: var(--content-block-dark-background);
        box-shadow: var(--standard-box-shadow);
        color: var(--light-text-standard); 
        border: 2px solid #00d7fe;
        border-radius: 1rem;

        h2 {
            color: var(--dark-text-high-emphasis);
        }

        .subsite-link {
            width: 50%;
            @include media-breakpoint-down(sm){
                width: 100%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
            }
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }

        .document-box {
            margin-right: 1rem;
            margin-top: 20px;
            position: relative;
            background: var(--box-dark-background);
            box-shadow: var(--standard-box-shadow);
            @media screen and (max-width: 61.875rem) {
                min-width: auto;
            }

            @media screen and (max-width: 504px) {
                width: 100%;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 8px;
                background: var(--highlight-border-colour);
                border-radius: 3px 0 0 3px;
            }              

            &:hover {
                .box-header {
                    .box-title {
                        text-decoration: underline;
                    }
                }
                &::before {
                    background: var(--secondary-main-colour);
                }              
            }

            .box-header {
                margin-bottom: 0px;

                .box-title {
                    text-transform: uppercase;
                    color: var(--header-highlight);
                    font-size: 0.75rem;
                    font-weight: 700;
                    letter-spacing: var(--font-non-h-header-letter-spacing);

                    .subsite-link {
                        text-decoration: none;
                        color: var(--light-text-standard);
                    }
                }
            }
        }

        .btn-lg {
            margin-top: 3rem;
        }

        .home_services_box {
            a .document-box .box-header {
                .box-title {
                    color: var(--dark-text-standard);  
                }

                &:hover {
                    .box-title {
                        text-decoration: underline;
                    }
                }
            } 

            width: 100%;
            @media screen and (max-width: 61.875rem) {
                width: 100%;
            }
        }
        .home_services_box_2 {
            width: 100%;
        }
    }   
    
    .content-block-background-image-full-width {
        background: url('./../images/feedback_vectors.png') no-repeat;
        background-position: bottom center;
    }
}

.other-services {
    .home_services_box {
        .box {
            min-height: 100%; 
            .padded-box {
                .btn {
                    position: absolute;
                    bottom: 20px;
                    @include media-breakpoint-down(xl) {
                        position: relative;
                        bottom: 0px;
                    }
                }
            }
        }
    }
}

.box.box-fit-content {
    height: auto;
}


.box-flex {
    display: flex;
    justify-content: center;
}

.box.box-subsite.box-fit-content {
    margin-right: 0;
}

.title-about {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -1.12px;
}

.box-container {
    width: 100%;
    margin-bottom: 20px;
}

.areas_of_law {
    .content-block.sub-page-banner-block {
        .box-header {
            svg {
                margin-right: 20px;
            }
        }
    }
}

.subsite-link {
    .box.dark-box.document-box {
        border-radius: var(--box-border-radius-2);
    }
}

.sub-page-banner-block .box {
    background: none;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 2px 0px #ccc;
}

.lawguide-section .box {
    .content-list-item {
        padding: 0;

        a {
            padding: 4px 10px;
        }
    }
}

@media (max-width: 600px){
    .title-spacing.d-flex.justify-content-center {
        img {
            display: none;
        }
    }
}
