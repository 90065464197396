.notification {
    padding: 0.625rem 1.25rem;
    margin: 0.625rem 0 0.9375rem;
    background-color: var(--bs-info-bg-subtle);
    border: 0.125rem solid var(--bs-info-border-subtle);
    color: var(--bs-info-text-emphasis);
  }

  .notification--error {
     background-color: var(--bs-danger-bg-subtle);
     border-color: var(--bs-danger-border-subtle) ;
     color: var(--bs-danger-text-emphasis);
  }

  .notification--success {
      background-color:  var(--bs-success-bg-subtle);
      border-color: var(--bs-success-border-subtle) ;
      color: var(--bs-success-text-emphasis);
   }

   .insurance-claim {
      a.insurance_link {
         color: var(--epoq-primary-blue);
         text-decoration: underline;
         &:hover {
            color: var(--secondary-main-colour);
            text-decoration: none;
            cursor: pointer;
         }
      }
   }