#document-evm-modal {

  a {
    text-decoration: none;
  }

    .e_evm_loading {
      text-align: center;
    }
  
    .e_evm_document_search_none {
      display: none;
      font-weight: bold;
      text-align: center;
      margin-top: 20px;
    }
  
    .e_evm_init_options {
      @include media-breakpoint-down(md){
        flex-direction: column;
      }

      .e_evm_init_option_template, .e_evm_init_option_document {
        width: 100%;

        // @include media-breakpoint-down(md){
        //   width: 100%;
        //   }
      }

      .row .col-sm .e_evm_init_option_document {
        width: 100%;
      }


      .e_evm_init_option_registration {
        background: #f4f4f4;
        border-radius: 5px;
        padding: 5px 5px 15px;
      }
  
      a {
        // border-radius: 3px;
        // box-shadow: 0 8px 6px -6px #ccc;
        // background-color: #fff;
        // border: 1px solid #bbb;
        // padding: 12px 35px 12px 18px;
        // margin: 10px 3% 0;
        // display: block;
        text-decoration: none;
        overflow: hidden;
        position: relative;
        display: flex;
        text-align: center;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
  
        strong {
          padding: 0 10px;
        }
  
        &#e_evm_init_option_start {
          margin-top: 20px;
        }
      }
  
      .e_evm_init_option_none {
        border-top: 1px dotted #ccc;
        margin-top: 30px;
        padding-top: 10px;
        clear: both;
        position: relative;
        width: 100%;
  
        &:before {
          content: "or";
          position: absolute;
          text-align: center;
          display: block;
          top: -25px;
          left: 48%;
          background: #fff;
          padding: 2px 7px;
          font-size: 22px;
        }
      }
    }
  
    .e_evm_transfer_data {
      margin: 10px 0;
      background: #fff;
      padding: 5px 10px 10px;
      overflow-y: scroll;
      max-height: 350px;
  
      table {
        width: 100%;
      }
    }
  
    table {
      th {
        label {
          margin: 0;
        }
      }
  
      tr:nth-child(even) {
        background-color: #efefef;
      }
    }
  
    .e_evm_document_select_search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.75rem 0;
  
      .e_evm_orderby_container {
        display: flex;
  
        @media screen and (max-width: 500px) {
          display: none;
        }
  
        .e_evm_orderby_label {
          font-weight: bold;
        }
  
        .e_evm_orderby {
          margin: 0;
          padding: 0;
  
          li {
            margin-left: 15px;
            display: inline-block;
          }
        }
      }
  
      .e_evm_document_select_search_template {
        width: 250px;
        border: 1px solid grey;
        padding: 2px 7px;
        border-radius: 6px;
        margin-left: 7px;
      }
  
    }
  
    .e_evm_document_select_document {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      background: #fafafa;
      border: 1px solid #ccc;
      padding: 10px 15px;
      margin-top: 10px;
  
      p {
        margin: 10px 0;
      }
  
      .e_evm_document_select_floaty {
        display: flex;
      }
  
      .evm_label {
        font-weight: bold;
      }
  
      .e_btn_evm_select_doc {
        display: flex;
        align-items: center;
      }
  
      .e_evm_document_select_stats {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
  
        p:first-child {
          margin-right: 10px;
        }
  
        .e_evm_document_count {
          width: 50px;
          border: 1px solid #ddd;
          background: #fff;
          display: inline-block;
          margin-right: 10px;
          position: relative;
          height: 12px!important;
          top: 2px!important;
  
          .e_evm_document_count_size {
            height: 10px;
            background: #666;
            display: inline-block;
            position: absolute!important;
            top: 0!important;
          }
        }
      }
  
    }
  
    #e_evm_fields_selected {
      text-align: center;
    }
  
    .evm_navigation_buttons {
      display: inline-block;
      width: 100%;
  
      .e_btn_back {
        float: left;
      }
  
      #e_evm_confirm_doc_data {
        float: right;
      }

      a {
        text-decoration: none;
      }
    }
  
  }
  .ui-dialog-titlebar {
    display: flex;
    justify-content: flex-start;
    .ui-dialog-title {
        float: left;
    }
    button {
        float: right;
    }
  }