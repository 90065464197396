/* --- tooltip --- */

.ui-tooltip {
    position: absolute;
    z-index: 9999;
    max-width: 300px;

    box-sizing: border-box;
    * { box-sizing: border-box; }

    background-color: #efefef;
    color: #222;

    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 18px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #222;

    .arrow {
        position: absolute;
        &.center{
            left: 50%;
        }
        &.top, &.bottom {
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            margin-left: -7px;
            height: 10px;
            width: 14px;

            &.left {
                display: none;
            }
            &.right {
                display: none;
            }
        }
        &.top {
            border-bottom: 10px solid #222;
            top: -10px;
        }
        &.bottom {
            border-top: 10px solid #222;
            bottom: -10px;
        }
    }
}

/* highlight lawguide tooltips */

.tooltip.lawguide_tooltip { border-bottom: 1px dotted; cursor: default; }